<div class="flex">
  <div class="flex-row">
    <div class="flex-col flex-col-10x">
      <a>Current courses</a>
    </div>
    <div class="flex-col flex-col-10x">
      <a>Transcript</a>
    </div>
    <div class="flex-col flex-col-10x">
      <a>All courses</a>
    </div>
  </div>
</div>

<!-- and an actual outlet elsewhere -->
<router-outlet></router-outlet>
