<div class="modal-header" *ngIf="isModal">
  <button type="button" class="close" aria-label="Close" (click)="cancelModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Competencies and Capabilities</h4>
</div>

<!-- <div class="modal-body"> -->
<div *ngIf="!competencies">
  <div class="load-spinner"></div>
</div>
<competencies-tree-view
  [competencies]="competencies"
  [selectedItems]="selectedItems"
  (selectedItemsChange)="selectedItemsChanged($event)"
  *ngIf="competencies?.length"></competencies-tree-view>
<!-- </div> -->

<div class="modal-footer" *ngIf="isModal">
  <button type="button" class="btn btn-warning" (click)="cancelModal()">Cancel</button>
  <button type="button" class="btn btn-success" (click)="applyFilter()">Apply</button>
</div>
