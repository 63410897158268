import { Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from 'core/authorization';
import { ISavedSearchItem } from 'modules/search/models/saved-searches.models';
import { EMPTY, Observable, Unsubscribable, catchError, finalize, from, of, tap } from 'rxjs';
import { SavedSearchesServive } from '../../services/saved-searches.service';
import { ManageSavedSearchesModalComponent } from './modal/manage-saved-searches.component';
import { SaveSearchComponent } from './modal/save-search.component';

@Component({
  standalone: false,
  selector: 'manage-saved-searches',
  templateUrl: './manage-saved-searches.component.html',
})
export class ManageSavedSearchesComponent implements OnDestroy {
  displayLimit = 100;
  savedSearches: ISavedSearchItem[];
  processing: boolean;

  user = this.currentUser.get();
  isAnonymous = this.user.anonymous;

  private dialogSubscriber?: Unsubscribable;
  private subscriber?: Unsubscribable;

  constructor(
    private ngModal: NgbModal,
    private currentUser: CurrentUserService,
    private savedSearchesServive: SavedSearchesServive,
  ) {}

  ngOnDestroy(): void {
    this.finalize();
    this.finalizeDialog();
  }

  open() {
    this.subscriber = this.fetchData().subscribe();
  }

  managedSavedSearches() {
    this.subscriber = this.fetchData().subscribe((savedSearches) => {
      const modalReference = this.ngModal.open(ManageSavedSearchesModalComponent, {
        backdrop: 'static',
        animation: true,
        size: 'xl',
      });

      const component = modalReference.componentInstance as ManageSavedSearchesModalComponent;

      component.savedSearches = savedSearches;

      this.dialogSubscriber = from(modalReference.result)
        .pipe(
          tap((items: ISavedSearchItem[]) => (this.savedSearches = items)),
          catchError(() => EMPTY),
          finalize(() => this.finalizeDialog()),
        )
        .subscribe();
    });
  }

  saveSearch() {
    this.subscriber = this.fetchData().subscribe(() => {
      const modalReference = this.ngModal.open(SaveSearchComponent, {
        backdrop: 'static',
        animation: true,
        size: 'xl',
      });

      this.dialogSubscriber = from(modalReference.result)
        .pipe(
          tap((item: ISavedSearchItem) => this.savedSearches.push(item)),
          catchError(() => EMPTY),
          finalize(() => this.finalizeDialog()),
        )
        .subscribe();
    });
  }

  get searches(): ISavedSearchItem[] {
    return this.savedSearches?.slice(0, this.displayLimit) || [];
  }

  private fetchData(): Observable<ISavedSearchItem[]> {
    if (!this.savedSearches) {
      this.processing = true;

      return this.savedSearchesServive.query(this.user.id).pipe(
        tap((searches) => (this.savedSearches = searches)),
        finalize(() => this.finalize()),
      );
    }

    return of(this.savedSearches).pipe(finalize(() => this.finalize()));
  }

  private finalizeDialog() {
    this.dialogSubscriber?.unsubscribe();
    this.dialogSubscriber = null;
  }

  private finalize() {
    this.processing = false;
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }
}
