<div class="row">
  <fieldset>
    <legend class="sr-only">Search</legend>

    <div class="col-sm-7" [ngClass]="{ 'col-sm-10': hasCustomFilters }">
      <div class="sm-margin-bottom">
        <keyword-search-form
          placeholder="Search"
          [(value)]="query"
          [pending]="processing"
          [disabled]="pending"
          (formSubmit)="onSubmit()">
          <manage-saved-searches *ngIf="!anonymous" class="input-group-btn search-controls"></manage-saved-searches>
        </keyword-search-form>
      </div>
    </div>

    <div class="col-sm-3" *ngIf="!hasCustomFilters">
      <extended-object-filter [disabled]="pending" (filterChanged)="applyFilter($event)"></extended-object-filter>
    </div>

    <div class="col-sm-2 col-xs-12 sm-margin-bottom-phone" *ngIf="!mobileView">
      <button type="button" class="btn btn-default btn-block" (click)="searchExport()">
        Export <span class="visible-lg-inline">Results</span>
      </button>
    </div>

    <search-mobile-action-panel *ngIf="mobileView" class="col-xs-12" [filters]="filters"></search-mobile-action-panel>
  </fieldset>
</div>

<div class="row">
  <!--Left filtering panel-->
  <div class="col-sm-4 col-md-3 col-xs-12" *ngIf="hasCustomFilters && !mobileView">
    <h4 class="no-margin-top">Filters</h4>
    <search-filter-view *ngFor="let s of filters.ui" [filterService]="s" [isOpen]="s.filter.open"></search-filter-view>

    <div *ngIf="advancedOptions">
      <search-filter-view
        *ngFor="let s of filters.advanced"
        [filterService]="s"
        [isOpen]="s.filter.open"></search-filter-view>
    </div>

    <div *ngIf="advancedVisible.length > 0" class="panel panel-sm panel-default">
      <div class="panel-body text-center">
        <a href="#" (click)="advancedOptions = !advancedOptions; $event.preventDefault()">
          <span class="fa" [ngClass]="{ 'fa-eye': !advancedOptions, 'fa-eye-slash': advancedOptions }"></span
          ><span *ngIf="!advancedOptions" class="xs-padding-left">Show Advanced Options</span
          ><span *ngIf="advancedOptions" class="xs-padding-left">Hide Advanced Options</span>
        </a>
      </div>
    </div>
  </div>

  <!--Main panel-->
  <div class="col-xs-12" [ngClass]="{ 'col-sm-8 col-md-9': hasCustomFilters && !mobileView }">
    <div>
      <div class="well well-sm sm-margin-top standard-margin-bottom">{{ itemsCount | number }} record(s) found.</div>
      <div class="label-list sm-margin-bottom">
        <selected-search-filters
          *ngFor="let f of filters.all"
          [filterService]="f"
          (filterChanged)="applyFilter($event)"></selected-search-filters>
      </div>

      <search-result
        [mode]="'simpleList'"
        [submit]="submitSubject"
        [itemsPerPage]="itemsPerPage"
        (itemCountChanged)="itemsCount = $event"
        (promise)="onFetchingChange($event)"></search-result>
    </div>
  </div>
</div>
