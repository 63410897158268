import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import { IQuiz } from '../models/quiz.model';

enum QuizEndpoints {
  QUERY = '/a/quizzes/:courseId/quizzes/',
  GET = '/a/quizzes/:quizId',
  PUT = '/a/quizzes/:quizId/',
  CLONE = '/a/quizzes/:quizId/?clone=true',
  POST = '/a/quizzes/',
}

@Injectable()
export class QuizService {
  private defaultProps: Partial<IQuiz> = {
    globalId: uuid.v4(),
    passingScoreRequired: false,
    resultsReviewAllowed: true,
    showCorrectAnswers: 0,
    resumeAllowed: false,
    active: true,
    questionDeliveryTypeId: 1,
    questions: [],
    resultTypeId: 1,
    scoreTypeId: 1,
  };

  constructor(private http: HttpClient) {}

  public get(quizId: number | string, permissions?: ReadonlyArray<string>, throwHttpErrors = false): Observable<IQuiz> {
    let params = new HttpParams();

    if (permissions) {
      params = params.appendAll({ permitted_for: permissions });
    }

    return this.http.get<IQuiz>(ElmsUtils.formatUrl(QuizEndpoints.GET, { quizId }), {
      params,
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public post(payload: Partial<IQuiz>): Observable<IQuiz> {
    return this.http.post<IQuiz>(QuizEndpoints.POST, payload);
  }

  public update(quizId: number, payload: Partial<IQuiz>): Observable<IQuiz> {
    return this.http.put<IQuiz>(ElmsUtils.formatUrl(QuizEndpoints.PUT, { quizId }), payload);
  }

  public delete(quizId: number | string, throwHttpErrors = false): Observable<IQuiz> {
    return this.http.delete<IQuiz>(ElmsUtils.formatUrl(QuizEndpoints.GET, { quizId }), {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public query(courseId: number): Observable<IQuiz[]> {
    return this.http.get<IQuiz[]>(ElmsUtils.formatUrl(QuizEndpoints.QUERY, { courseId }));
  }

  public clone(quizId: number | string, throwHttpErrors = false): Observable<IQuiz> {
    return this.http.post<IQuiz>(ElmsUtils.formatUrl(QuizEndpoints.CLONE, { quizId }), {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public newQuiz(quizAttr?: Partial<IQuiz>): Partial<IQuiz> {
    return {
      ...this.defaultProps,
      ...(quizAttr || {}),
    };
  }
}
