import { Injectable } from '@angular/core';
import { ISearchFilter } from 'modules/search/models/search-filters.models';
import { CourseFormatFilterService } from './course-format-filter.service';

@Injectable()
export class CourseLabelFilterService extends CourseFormatFilterService {
  public override filter: ISearchFilter = {
    name: 'courseLabelFilter',
    label: 'Format Label',
    term: 'label_id',
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [],
    unassignedOption: {
      text: 'Unassigned',
      value: 'unassigned',
    },

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  public override load(): void {
    this.courseService.getCourseOptions().subscribe((options) => {
      this.filter.items = options.labels
        .filter((l) => l.active)
        .map((l) => ({ id: l.id, text: l.name, value: l.id.toString() }));

      this.initSelectedItems();
    });
  }
}
