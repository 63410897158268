import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbAccordionModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { UIRouterModule } from '@uirouter/angular';
import { SearchDeprecatedModule } from 'app/deprecated/search/search.deprecated.module';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CourseProvidersOrganizationsModule } from 'modules/course/providers/organizations/course-providers-organizations.module';
import { CourseTilesModule } from 'modules/course/views/tiles/course-tiles.module';
import { LearningSeriesModule } from 'modules/learning-series/learning-series.module';
import { CompetencyModule } from '../../competency/competency.module';
import { ManageSavedSearchesComponent } from '../components/saving/manage-saved-searches.component';
import { ManageSavedSearchesModalComponent } from '../components/saving/modal/manage-saved-searches.component';
import { SaveSearchComponent } from '../components/saving/modal/save-search.component';
import { SuggestionsService } from '../services';
import { AdvancedSearchTermsComponent } from './components/advanced-search-terms.component';
import { BooleanFilterComponent } from './components/filtering/boolean-filter.component';
import { BooleanSubFilterComponent } from './components/filtering/boolean-sub-filter.component';
import { DatesChoiceFilterComponent } from './components/filtering/dates-choice-filter.component';
import { ExtendedObjectFilterComponent } from './components/filtering/extended-object-filter.component';
import { FullScreenFilterViewComponent } from './components/filtering/full-screen-filter-view.component';
import { CompetencyFilterComponent } from './components/filtering/modal/competency-filter.component';
import { CourseAttributeFilterComponent } from './components/filtering/modal/course-attribute-filter.component';
import { GroupTagsFilterComponent } from './components/filtering/modal/group-tags-filter.component';
import { SearchableMultiChoiceComponent } from './components/filtering/modal/searchable-multi-choice.component';
import { SessionLabelFilterComponent } from './components/filtering/modal/session-label-filter.component';
import { MultiChoiceFilterComponent } from './components/filtering/multi-choice-filter.component';
import { NumberChoiceFilterComponent } from './components/filtering/number-choice-filter.component';
import { PopupMultiChoiceComponent } from './components/filtering/popup-multi-choice.component';
import { PriceFilterComponent } from './components/filtering/price/price-filter.component';
import { PriceFormComponent } from './components/filtering/price/price-form.component';
import { PromptingFilterComponent } from './components/filtering/prompting-filter.component';
import { SearchFilterViewComponent } from './components/filtering/search-filter-view.component';
import { SearchableMultiChoiceFilterComponent } from './components/filtering/searchable-multi-choice-filter.component';
import { SelectedFilterRatingComponent } from './components/filtering/selected-filter-rating.component';
import { SelectedSearchFiltersComponent } from './components/filtering/selected-search-filters.component';
import { SingleChoiceFilterComponent } from './components/filtering/single-choice-filter.component';
import { KeywordSearchFormComponent } from './components/keyword-search-form.component';
import { SearchMobileActionPanelComponent } from './components/search-mobile-action-panel.component';
import { SearchResultRowComponent } from './components/search-result-row.component';
import { SearchResultStatsComponent } from './components/search-result-stats.component';
import { SearchResultComponent } from './components/search-result.component';
import { SortingSearchOptionsComponent } from './components/sorting-search-options.component';
import { IsSearchItemPipe } from './pipes/is-search-item.pipe';
import { CourseCommonModule } from 'modules/course/common/course-common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LmsComponentsModule,
    CourseTilesModule,
    CompetencyModule,
    LearningSeriesModule,
    CourseProvidersOrganizationsModule,
    NgbAccordionModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbPaginationModule,
    CdkTreeModule,
    UIRouterModule,
    SearchDeprecatedModule,
    CourseCommonModule,
  ],
  declarations: [
    IsSearchItemPipe,
    KeywordSearchFormComponent,
    SortingSearchOptionsComponent,
    AdvancedSearchTermsComponent,
    ExtendedObjectFilterComponent,
    SelectedFilterRatingComponent,
    SearchFilterViewComponent,
    SelectedSearchFiltersComponent,
    BooleanFilterComponent,
    BooleanSubFilterComponent,
    MultiChoiceFilterComponent,
    PriceFilterComponent,
    PriceFormComponent,
    SingleChoiceFilterComponent,
    PromptingFilterComponent,
    DatesChoiceFilterComponent,
    NumberChoiceFilterComponent,
    FullScreenFilterViewComponent,
    PopupMultiChoiceComponent,
    CourseAttributeFilterComponent,
    CompetencyFilterComponent,
    GroupTagsFilterComponent,
    SessionLabelFilterComponent,
    SearchableMultiChoiceFilterComponent,
    SearchableMultiChoiceComponent,
    SearchResultStatsComponent,
    SearchResultComponent,
    SearchResultRowComponent,
    SearchMobileActionPanelComponent,
    ManageSavedSearchesComponent,
    SaveSearchComponent,
    ManageSavedSearchesModalComponent,
  ],
  exports: [
    IsSearchItemPipe,
    KeywordSearchFormComponent,
    SortingSearchOptionsComponent,
    AdvancedSearchTermsComponent,
    ExtendedObjectFilterComponent,
    SearchFilterViewComponent,
    SearchResultStatsComponent,
    SelectedSearchFiltersComponent,
    SearchResultComponent,
    SearchMobileActionPanelComponent,
    ManageSavedSearchesComponent,
  ],
  providers: [SuggestionsService],
})
export class SearchCommonModule {}
