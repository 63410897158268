<div class="row">
  <div class="col-sm-12">
    <div class="flex standard-margin-bottom">
      <div class="flex-row">
        <div class="flex-col flex-col-grow text-left">
          <a
            href=""
            class="btn btn-link no-padding-left modern-back-button"
            title="Go back"
            (click)="$event.stopPropagation(); $event.preventDefault(); goBack()"
            ><span class="fa fa-chevron-left"></span>Back</a
          >
        </div>
        <div class="flex-col text-right">
          <div class="standard-margin-left">
            <a
              [uiSref]="'edit.resource'"
              [uiParams]="{ resourceId: resource.id }"
              *ngIf="editable"
              title="Edit resource"
              class="btn btn-default btn-block"
              >Edit Resource
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section>
  <h2 class="no-margin-top xs-margin-bottom">{{ resource.title }}</h2>
  <resource-category-path
    [categoryId]="resource.categoryId"
    [includeCurrent]="true"
    *ngIf="resource.categoryId && editable"></resource-category-path>

  <hr class="horizontal-divider" style="margin: 10px 0px" role="divider" aria-hidden="true" />
  <article class="standard-margin-bottom hyphens-auto" [innerHTML]="resource.description | markdownToHtml"></article>

  <div class="xs-margin-bottom">
    <strong>{{ resource.url ? 'Visit the site' : 'Download the resource' }}</strong>
  </div>

  <ul class="list-group list-group-striped">
    <li class="list-group-item hyphens-auto" style="padding: 6px 12px">
      <!--link-->
      <div *ngIf="resource.url" class="text-overflow-ellipsis text-link">
        <span class="fa fa-external-link inline-block standard-margin-right text-default"></span>
        <a title="Visit the site" href="{{ resource.url }}" target="_blank">{{ resource.url }}</a>
      </div>
      <!--downloadable file -->
      <div class="d-flex space-between" *ngIf="resource.file">
        <div class="text-overflow-ellipsis text-link">
          <span class="fa fa-file inline-block standard-margin-right text-default"></span>
          <a title="Download the resource" target="_blank" href="{{ '/a/resources/' + resource.id + '/content/' }}">{{
            resource.file.fileName
          }}</a>
        </div>
        <span style="flex-basis: 10em; min-width: 4.5em; text-align: right">
          {{ formatFileSize(resource.file.size) }}
        </span>
      </div>
    </li>
  </ul>
</section>

<div class="row standard-margin-bottom" *ngIf="resource.restricted">
  <div class="col-xs-12"><i class="fa fa-lock" aria-hidden="true"></i> Available only to administrators</div>
</div>

<div class="row">
  <div class="col-xs-12">
    <entity-author [entity]="resource" mode="updatedData"></entity-author>
  </div>
</div>
