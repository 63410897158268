<span>
  <a title="View All Resources" *ngIf="!readonlyView" href="#" [uiSref]="'main.resourcesCatalog'">Categories</a>
  <span *ngIf="readonlyView">Categories</span>
</span>
<span *ngFor="let category of categories">
  <span>&nbsp;>&nbsp;</span>
  <a
    title="View Category"
    *ngIf="!readonlyView"
    href="#"
    [uiSref]="'main.resourceCategory'"
    [uiParams]="{ categoryId: category.id }"
    >{{ category.title }}</a
  >
  <span *ngIf="readonlyView">{{ category.title }}</span>
</span>
