import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { ParamDeclaration } from '@uirouter/core/lib/params/interface';
import { CurrentUserService } from 'core/authorization';
import { UrlParamService } from 'core/navigation';
import { IUrlParams, OnUrlParamsChange } from 'core/navigation/models/navigation-url.model';
import { Subject } from 'rxjs';
import { searchTerms } from '../common';
import { SearchCommonModule } from '../common/search-common.module';
import { userSearchFilterProviders } from '../common/services';
import { SearchFiltersProviderService } from '../common/services/search-filters-provider.service';
import { ISearchFilterChanges, ISearchFilterServices, ISearchFilterTerm } from '../models/search-filters.models';
import { ISearchItem } from '../models/search.models';

@Component({
  selector: 'search-component',
  templateUrl: './search.component.html',
  imports: [CommonModule, SearchCommonModule],
  providers: [UrlParamService, ...userSearchFilterProviders],
})
export class SearchComponent implements OnInit, OnUrlParamsChange {
  query: string;
  error: string;
  itemsCount = 0;
  items: ISearchItem[];
  pending: boolean;
  processing: boolean;
  currentPage: number;
  filters: ISearchFilterServices;
  sorting?: ISearchFilterTerm;
  sortOptions = this.searchFiltersProvider.sortTerms;
  showOptions = this.searchFiltersProvider.filteringTerms;

  searchType: string;
  searchExportLink: string;
  hasCustomFilters: boolean;
  advancedOptions: boolean;
  mobileView: boolean;
  anonymous: boolean;

  submitSubject = new Subject<void>();

  readonly itemsPerPage: number = 20;

  private paramDeclaration: { [key: string]: ParamDeclaration };
  private readonly defaultUrlParams = {
    showInactive: !this.showOptions.find((i) => i.term === 'showInactive'),
    showExpired: !this.showOptions.find((i) => i.term === 'showExpired'),
    showUnpublished: !this.showOptions.find((i) => i.term === 'showUnpublished'),
    sort: this.sortOptions.find((i) => i.term === 'relevance'),
    page: 1,
  };

  constructor(
    private window: Window,
    protected urlService: UrlParamService,
    private activeState: UIRouterGlobals,
    private currentUser: CurrentUserService,
    private searchFiltersProvider: SearchFiltersProviderService,
  ) {}

  @HostListener('window:resize')
  onResize() {
    this.setWindowWidth();
  }

  ngOnInit() {
    this.paramDeclaration = this.activeState.current.params;
    this.filters = this.searchFiltersProvider.loadFilters();
    this.hasCustomFilters = this.filters.ui.length > 0;
    this.anonymous = this.currentUser.get().anonymous;

    this.setWindowWidth();
    this.urlService.subscribe((p) => this.ngOnUrlParamsChange(p));
  }

  ngOnUrlParamsChange(params: IUrlParams): void {
    if (!this.isOldUrl(params)) {
      this.query = params.query as string;
      this.currentPage = Number(params.page) || 1;
      this.sorting = params.sort ? this.sortOptions.find((i) => i.term === params.sort) : this.defaultUrlParams.sort;
      this.showOptions.forEach((i) => (i.value = Boolean(params[i.term]) || this.defaultUrlParams[i.term]));
      this.searchType = params.type as string;
    } else {
      this.urlService.replace(this.transformUrl(params));
    }
  }

  get advancedVisible() {
    return this.filters.advanced.filter((i) => i.filter.visible);
  }

  searchExport() {
    const params = new URLSearchParams(
      Object.entries(this.urlService.urlParams).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(',') : value.toString(),
      ]),
    );

    this.window.open(params.size ? `/a/search/export/?${params.toString()}` : '/a/search/export/');
  }

  applyFilter(changes: ISearchFilterChanges) {
    if (this.items?.length && 'type' in changes) {
      this.items = [];
      this.itemsCount = 0;
    }

    this.urlService.navigate({ ...changes, page: null });
  }

  applySort(filter: ISearchFilterTerm) {
    this.urlService.navigate({ sort: filter.term === this.defaultUrlParams.sort.term ? null : filter.term });
  }

  onSubmit() {
    this.pending = true;

    if (this.query !== this.urlService.urlParams.query) {
      this.urlService.navigate({ query: this.query.trim(), page: null });
    } else if (!this.processing) {
      this.submitSubject.next();
    }
  }

  onFetchingChange(fetching: boolean) {
    this.processing = fetching;

    if (this.pending && !fetching) {
      this.pending = false;
    }
  }

  private setWindowWidth() {
    this.mobileView = this.window.innerWidth < 768;
  }

  private isOldUrl(params: IUrlParams): boolean {
    return Object.keys(params).some(
      (t) => !Array.isArray(params[t]) && !this.paramDeclaration[t]?.type && params[t].includes(','),
    );
  }

  private transformUrl(params: IUrlParams): IUrlParams {
    const currentTerms = searchTerms.filter((i) => i in params && !(i in this.defaultUrlParams));

    return Object.fromEntries(
      currentTerms.map((i) => {
        const value = params[i];

        return [i, Array.isArray(value) || !value.includes(',') ? value : (value as string).split(',')];
      }),
    );
  }
}
