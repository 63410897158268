<div *ngIf="filter.visible && filter.items.length > 0">
  <div *ngIf="!filter.appearance || filter.appearance === 'panel'" class="truncate-labels">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button type="button" ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{ filter.label }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <price-form [filterService]="filterService" [status]="status"></price-form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!fullScreen && filter.appearance === 'button'">
    <div
      class="btn-group btn-block"
      ngbDropdown
      [autoClose]="'outside'"
      #dropDown="ngbDropdown"
      (openChange)="status.isOpen = $event">
      <button id="SearchFilters" type="button" class="btn btn-default btn-block" ngbDropdownToggle>
        {{ filter.label }}
        <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu class="full-width">
        <price-form [filterService]="filterService" [status]="status" (applied)="dropDown.close()"></price-form>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen">
    <price-form
      [filterService]="filterService"
      [anyEvent]="fullScreen"
      class="display-block col-xs-12"
      style="padding-top: 3px"
      [status]="status"></price-form>
  </div>
</div>
