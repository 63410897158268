import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ISearchResponse } from 'modules/search/models/search.models';
import { Observable } from 'rxjs';
import { ISuggestionItem } from '../models/suggestions.models';

enum SuggestionServiceEndpoints {
  SUGGESTION = '/a/search/completion/',
  USER_SUGGESTION = '/a/search/user/',
}

@Injectable()
export class SuggestionsService {
  constructor(private http: HttpClient) {}

  getSuggestions(text: string, type?: string, throwHttpErrors = false): Observable<ISearchResponse<ISuggestionItem>> {
    const params = new HttpParams({ fromObject: { text: text, type: type } });

    return this.http.get<ISearchResponse<ISuggestionItem>>(SuggestionServiceEndpoints.SUGGESTION, {
      params,
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  getUserSuggestions(params?: HttpParams): Observable<any> {
    return this.http.get(SuggestionServiceEndpoints.USER_SUGGESTION, { params: params ?? {} });
  }
}
