import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { NetworkModule } from '../../network/network.module';
import { SearchCommonModule } from '../../search/common/search-common.module';
import { UserAchievementsModule } from '../../user-achievements/user-achievements.module';
import { UserModule } from '../user.module';
import { UserNetworkComponent } from './components/user-network.component';
import { UserProfileShortInfoComponent } from './components/user-profile-short-info-component';
import { userNetworkRoutingModule } from './user-network-routing.module';

@NgModule({
  imports: [
    LmsComponentsModule,
    CommonModule,
    UIRouterUpgradeModule.forChild({ states: userNetworkRoutingModule }),
    UserAchievementsModule,
    FormsModule,
    NetworkModule,
    UserModule,
    SearchCommonModule,
  ],
  declarations: [UserNetworkComponent, UserProfileShortInfoComponent],
  providers: [],
})
export class UserNetworkModule {}
