import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { CookieService } from 'ngx-cookie-service';
import { resourcesRoutingModule } from './resources-routing.module';
import { ResourceCategoryService } from './services/resource-category.service';
import { ResourceService } from './services/resource.service';
import { ResourceCategoryPathComponent } from './view/categories/category-path.component';
import { ResourceDetailsComponent } from './view/resource-details.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    UIRouterUpgradeModule.forChild({ states: resourcesRoutingModule }),
    LmsComponentsModule,
  ],
  declarations: [ResourceCategoryPathComponent, ResourceDetailsComponent],
  providers: [HttpClient, CookieService, ResourceService, ResourceCategoryService],
  exports: [ResourceCategoryPathComponent],
})
export class ResourcesModule {}
