<ul [ngClass]="mode === 'tiles' ? 'list-unstyled d-flex flex-direction-column gap-15' : 'content-list'">
  <li [ngClass]="mode === 'tiles' ? '' : 'content-list-item'" *ngFor="let item of items">
    <search-result-row [item]="item" [mode]="mode" [disabled]="processing" [smallView]="smallView"></search-result-row>
  </li>
</ul>

<div class="text-center">
  <ngb-pagination
    *ngIf="itemCount > itemsPerPage"
    [collectionSize]="itemCount"
    [maxSize]="smallView ? 3 : 10"
    [pageSize]="itemsPerPage"
    [rotate]="true"
    [ellipses]="false"
    (pageChange)="pageChanged($event)"
    [boundaryLinks]="true"
    [disabled]="processing"
    [page]="currentPage"
    size="sm"
    class="pagination-sm">
    <ng-template ngbPaginationFirst>&laquo;</ng-template>
    <ng-template ngbPaginationLast>&raquo;</ng-template>
    <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
    <ng-template ngbPaginationNext>&rsaquo;</ng-template>
    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
  </ngb-pagination>
</div>
