import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ElmsUtils } from 'core/utils';
import { IExternalApplicationUser } from 'modules/external-applications/models/external-application-user.model';
import { Observable } from 'rxjs';

enum UserExternalApplicationEndpoints {
  GET = '/a/users/:userId/external-applications/:applicationId/',
  QUERY = '/a/users/:userId/external-applications/',
  CREATE = '/a/users/:userId/external-applications/',
  DELETE = '/a/users/:userId/external-applications/:applicationId/',
}

@Injectable()
export class UserExternalApplicationService {
  constructor(private http: HttpClient) {}

  public get(userId: number, applicationId: number): Observable<IExternalApplicationUser> {
    return this.http.get<IExternalApplicationUser>(
      ElmsUtils.formatUrl(UserExternalApplicationEndpoints.GET, { userId, applicationId }),
    );
  }

  public query(userId: number): Observable<IExternalApplicationUser[]> {
    return this.http.get<IExternalApplicationUser[]>(
      ElmsUtils.formatUrl(UserExternalApplicationEndpoints.QUERY, { userId }),
    );
  }

  public save(
    userId: number,
    payload: {
      applicationId?: number;
      applicationIds?: number[];
    },
  ): Observable<IExternalApplicationUser> {
    if (!payload.applicationId && !payload.applicationIds) {
      throw new Error();
    }

    return this.http.post<IExternalApplicationUser>(
      ElmsUtils.formatUrl(UserExternalApplicationEndpoints.CREATE, { userId }),
      payload,
    );
  }

  public delete(userId: number, applicationId: number): Observable<void> {
    return this.http.delete<void>(
      ElmsUtils.formatUrl(UserExternalApplicationEndpoints.DELETE, { userId, applicationId }),
    );
  }
}
