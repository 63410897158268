import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { QuizPlayerQuestionComponent } from 'modules/quiz/player/quiz-player-question.component';
import { QuizPlayerComponent } from 'modules/quiz/player/quiz-player.component';
import { QuizOptionsService } from 'modules/quiz/services/quiz-options.service';
import { QuizPlayerService } from 'modules/quiz/services/quiz-player.service';
import { QuizQuestionService } from 'modules/quiz/services/quiz-question.service';
import { QuizResultService } from 'modules/quiz/services/quiz-result.service';
import { QuizService } from 'modules/quiz/services/quiz.service';
import { QuizTemporaryFilesService } from 'modules/quiz/services/temporary-files.service';

@NgModule({
  imports: [LmsComponentsModule, CommonModule, FormsModule, NgbProgressbar],
  declarations: [QuizPlayerQuestionComponent, QuizPlayerComponent],
  providers: [
    QuizService,
    QuizQuestionService,
    QuizResultService,
    QuizPlayerService,
    { provide: QuizTemporaryFilesService },
    {
      provide: 'quizTimeTrackingSettings',
      useValue: {
        interval: 10e3,
      },
    },
    QuizOptionsService,
  ],
  exports: [QuizPlayerQuestionComponent, QuizPlayerComponent],
})
export class QuizModule {}
