import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import _ from 'lodash';
import { QuizQuestionType, QuizResultStatus } from 'modules/quiz';
import { IQuestion, IQuizResult } from 'modules/quiz/models/quiz.model';
import { QuizResultService } from 'modules/quiz/services/quiz-result.service';
import moment from 'moment';
import { EMPTY, Observable, Subject, Unsubscribable, filter, finalize, interval, map, of, tap } from 'rxjs';

export enum QuizPlayerEvent {
  started = 'started',
  finished = 'finished',
  questionChanged = 'questionChanged',
  error = 'error',
}

export interface IQuizPlayerEvent {
  type: QuizPlayerEvent;
  quizResult?: IQuizResult;
  message?: string;
  handleQuizResult?: boolean;
}

export interface IQuizTimeSettings {
  interval: number;
}

export class QuizPlayer {
  playerCurrentStep: string;
  endTime: any;
  started: boolean;
  ownQuiz: boolean;
  currentQuestionIndex: number;
  questionsProgress: number;
  currentQuestion: IQuestion;
  customPlayerSteps: string[];

  private finishing = false;
  private timeTrackingInterval: Unsubscribable;
  private subject = new Subject<IQuizPlayerEvent>();

  constructor(
    private quizResult: IQuizResult,
    private quizResultService: QuizResultService,
    private currentUser: any,
    private quizTimeTrackingSettings: IQuizTimeSettings,
    private notificationService: NotificationService,
  ) {}

  on(event: QuizPlayerEvent) {
    return this.subject.asObservable().pipe(filter((e) => e.type === event));
  }

  emit(event: IQuizPlayerEvent) {
    this.subject.next(event);
  }

  get result() {
    return this.quizResult;
  }

  init() {
    const failedNotLastAttempt =
      this.quizResult.statusId === QuizResultStatus.failed &&
      (!this.quizResult.quiz.attemptsAllowed || this.quizResult.attempt >= this.quizResult.quiz.attemptsAllowed);

    if (
      _.includes(
        [QuizResultStatus.passed, QuizResultStatus.pending, QuizResultStatus.completed],
        this.quizResult.statusId,
      ) ||
      failedNotLastAttempt
    ) {
      this.finish(true);
    } else if (this.quizResult.quiz.resumeAllowed && this.quizResult.alreadyStarted) {
      this.playerCurrentStep = 'continue';
      this.started = false;
    } else if (!this.quizResult.quiz.resumeAllowed && this.quizResult.alreadyStarted) {
      this.playerCurrentStep = 'finishAttempt';
    } else {
      this.playerCurrentStep = 'start';
      this.started = false;
    }

    this.ownQuiz = this.quizResult.userId === this.currentUser.id;
  }

  start() {
    this.started = true;
    this.currentQuestionIndex = 0;
    this.endTime = null;

    if (this.playerCurrentStep === 'continue') {
      this.currentQuestionIndex = this.getFirstNotAnsweredQuestionIndex();
    }

    if (
      !this.quizResultService.isCompleted(this.quizResult) &&
      (this.quizResult.quiz.timeLimit > 0 || this.quizResult.quiz.resumeAllowed)
    ) {
      this._changeQuestionsProgress();

      //Call WS for quiz finish and score calculation
      this.quizResultService.start(this.quizResult.id).subscribe((response) => {
        this.playerCurrentStep = 'questions';

        if (this.quizResult.quiz.timeLimit > 0) {
          this.endTime = moment(moment.now()).add(response.secondsLeft, 'seconds');

          if (!this.endTime || moment().isAfter(this.endTime)) {
            // Only in such case possible situation when on start we immediately complete quiz.
            console.error('The problem with init end time for the time tracking');
          }

          this.subject.next({ type: QuizPlayerEvent.started });
          this.startTimeTracking();
        }
      });
    } else {
      this.quizResultService.get(this.quizResult.id).subscribe((quizResult) => {
        Object.assign(this.quizResult, quizResult);
        this._changeQuestionsProgress();

        this.playerCurrentStep = 'questions';
        this.subject.next({ type: QuizPlayerEvent.started });
      });
    }
  }

  finish(handleQuizResult?: boolean) {
    this.playerCurrentStep = 'finish';
    this.endTime = null;

    if (!this.quizResultService.isCompleted(this.quizResult) || handleQuizResult) {
      //Call WS for quiz finish and score calculation
      this.finishing = true;
      this.quizResultService
        .finish(this.quizResult, handleQuizResult)
        .pipe(finalize(() => (this.finishing = false)))
        .subscribe(() => {
          this.subject.next({ type: QuizPlayerEvent.finished, handleQuizResult });
        });
    }
  }

  skip() {
    this.playerCurrentStep = 'skipped';
    this.endTime = null;
  }

  nextQuestion(): Observable<null> {
    if (this.playerCurrentStep !== 'questions') {
      return EMPTY;
    }

    if (
      !this.quizResultService.questionHasAnswer(this.currentQuestion) &&
      !this.quizResultService.isCompleted(this.quizResult)
    ) {
      return EMPTY;
    }

    if (
      this.currentQuestion &&
      this.currentQuestion.answerLimit &&
      _.includes([QuizQuestionType.checkAllThatApply], this.currentQuestion.typeId)
    ) {
      if (_.filter(this.currentQuestion.answerVariants, 'selected').length > this.currentQuestion.answerLimit) {
        this.notificationService.error(`Select up to ${this.currentQuestion.answerLimit} option(s)`, 2e3);

        return EMPTY;
      }
    }

    let endQuiz = false;
    const switchQuestion = () => {
      if (this.currentQuestionIndex < this.quizResult.questions.length - 1) {
        let selectedAnswerVariants = null;

        if (this.quizResult.questions[this.currentQuestionIndex].answerVariants) {
          selectedAnswerVariants = _.filter(
            this.quizResult.questions[this.currentQuestionIndex].answerVariants,
            'selected',
          );
        }

        if (selectedAnswerVariants && selectedAnswerVariants.length === 1 && selectedAnswerVariants[0].nextQuestionId) {
          if (selectedAnswerVariants[0].nextQuestionId > 0) {
            const previousQuestionIndex = this.currentQuestionIndex;

            this.currentQuestionIndex = _.indexOf(
              _.map(this.quizResult.questions, 'questionId'),
              selectedAnswerVariants[0].nextQuestionId,
            );
            this.quizResult.questions[this.currentQuestionIndex].previousQuestionIndex = previousQuestionIndex;
          } else {
            endQuiz = true;
          }
        } else {
          this.currentQuestionIndex++;
        }

        this._changeQuestionsProgress();
      } else {
        endQuiz = true;
      }

      if (endQuiz) {
        if (
          _.includes(this.customPlayerSteps, 'pendingCompletion') &&
          !this.quizResultService.isCompleted(this.quizResult)
        ) {
          this.playerCurrentStep = 'pendingCompletion';
        } else {
          this.finish(false);
        }
      }

      return null;
    };

    if (this.ownQuiz && !this.quizResultService.isCompleted(this.quizResult)) {
      return this.saveCurrentQuestionAnswer().pipe(
        tap(() => switchQuestion()),
        map(() => null),
      );
    } else {
      return of(switchQuestion());
    }
  }

  previousQuestion() {
    if (this.playerCurrentStep !== 'questions') {
      return;
    }

    requestAnimationFrame(() => {
      if (this.currentQuestionIndex > 0) {
        if (this.quizResult.questions[this.currentQuestionIndex].previousQuestionIndex >= 0) {
          this.currentQuestionIndex = this.quizResult.questions[this.currentQuestionIndex].previousQuestionIndex;
        } else {
          this.currentQuestionIndex--;
        }
      }

      this._changeQuestionsProgress();
    });
  }

  startTimeTracking() {
    this.stopTimeTracking();

    this.timeTrackingInterval = interval(this.quizTimeTrackingSettings.interval).subscribe(() => {
      if (this.quizResultService.isCompleted(this.quizResult) || !this.endTime || moment().isAfter(this.endTime)) {
        this.stopTimeTracking();

        if (!this.quizResultService.isCompleted(this.quizResult) && !this.finishing) {
          this.finishing = true;
          this.quizResultService
            .finish(this.quizResult)
            .pipe(finalize(() => (this.finishing = false)))
            .subscribe(() => {
              this.subject.next({ type: QuizPlayerEvent.finished, quizResult: this.quizResult });
            });
        }

        if (moment().isAfter(this.endTime)) {
          this.playerCurrentStep = 'timeEnds';
        }
      } else {
        this.quizResultService.timeTracking(this.quizResult.id).subscribe((result) => {
          this.quizResult.statusId = result.statusId;
          this.quizResult.statusName = result.statusName;
          this.quizResult.score = result.score;
          this.quizResult.scorePercentage = result.scorePercentage;

          if (this.quizResultService.isCompleted(this.quizResult)) {
            this.stopTimeTracking();

            if (this.playerCurrentStep !== 'finish') {
              this.playerCurrentStep = 'timeEnds';
            }
          }
        });
      }
    });
  }

  stopTimeTracking() {
    this.timeTrackingInterval?.unsubscribe();
    delete this.timeTrackingInterval;
  }

  getCurrentQuestion() {
    return this.quizResult.questions[this.currentQuestionIndex];
  }

  deinit() {
    this.stopTimeTracking();
    this.subject.complete();
  }

  private saveCurrentQuestionAnswer() {
    return this.quizResultService.saveQuestionAnswer(this.quizResult.id, this.currentQuestion);
  }

  private getFirstNotAnsweredQuestionIndex() {
    let question = this.quizResult.questions[0];
    let i = 0;
    let found = false;

    while (!found && i < this.quizResult.questions.length) {
      if (!question.answered) {
        found = true;
      } else {
        let selectedAnswerVariants = null;

        if (question.answerVariants) {
          selectedAnswerVariants = _.filter(question.answerVariants, 'selected');
        }

        if (selectedAnswerVariants && selectedAnswerVariants.length === 1 && selectedAnswerVariants[0].nextQuestionId) {
          const previousQuestionIndex = i;

          i = _.indexOf(_.map(this.quizResult.questions, 'questionId'), selectedAnswerVariants[0].nextQuestionId);
          this.quizResult.questions[i].previousQuestionIndex = previousQuestionIndex;
        } else {
          i++;
        }

        question = this.quizResult.questions[i];
      }
    }

    if (question) {
      return _.indexOf(this.quizResult.questions, question);
    }

    return 0;
  }

  private _changeQuestionsProgress() {
    if (this.quizResult.questions.length) {
      this.questionsProgress = Math.round((this.currentQuestionIndex / this.quizResult.questions.length) * 100);
      this.currentQuestion = this.getCurrentQuestion();

      this.subject.next({ type: QuizPlayerEvent.questionChanged });
    } else {
      this.subject.next({ type: QuizPlayerEvent.error, message: 'There are no questions defined.' });
    }
  }
}

@Injectable()
export class QuizPlayerService implements OnDestroy {
  private players: QuizPlayer[] = [];

  constructor(
    private quizResultService: QuizResultService,
    private currentUser: CurrentUserService,
    @Inject('quizTimeTrackingSettings') private quizTimeTrackingSettings: IQuizTimeSettings,
    private notificationService: NotificationService,
  ) {}

  getPlayer(quizResult: IQuizResult): QuizPlayer {
    const player = new QuizPlayer(
      quizResult,
      this.quizResultService,
      this.currentUser.get(),
      this.quizTimeTrackingSettings,
      this.notificationService,
    );

    player.init();

    this.players.push(player);

    return player;
  }

  ngOnDestroy() {
    this.players.forEach((player) => {
      player.deinit();
    });
    this.players.length = 0;
  }
}
