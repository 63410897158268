import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { UserLearningComponent } from 'modules/user-learning/components/user-learning.component';

export const userLearningRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.myLearning',
    url: '^/learning',
    component: UserLearningComponent,
    data: {
      label: 'My learning',
      availableFor: ['regularUser', 'admin'],
    },
  },
];
