<div class="row no-margin-left no-margin-right">
  <div class="col-sm-12 col-xs-12 no-padding-left no-padding-right">
    <form class="no-margin-bottom" #searchForm="ngForm" ngForm action (ngSubmit)="onSubmit(searchForm.form)">
      <label for="query" class="sr-only">Search</label>
      <div class="input-group">
        <ng-content select=".search-controls"></ng-content>
        <input
          id="query"
          type="search"
          name="query"
          class="form-control"
          [title]="placeholder"
          [disabled]="disabled"
          [maxlength]="250"
          [minlength]="minLength || 0"
          #search="ngModel"
          (ngModelChange)="onChange($event)"
          [placeholder]="placeholder"
          [(ngModel)]="value"
          [autoFocus]="autoFocus && !disabled && !pending" />

        <span class="input-group-btn">
          <button
            type="submit"
            class="btn btn-default"
            aria-label="Search"
            title="Search"
            data-spinner-color="#000"
            [ladda]="disabled || pending">
            <span class="fa fa-search" aria-hidden="true"></span>
          </button>
        </span>
      </div>
      <div class="text-danger xs-margin-top xs-margin-bottom" *ngIf="search.errors?.minlength">
        <ng-content select=".search-errors-minlenght"></ng-content>
      </div>
    </form>
  </div>
</div>
