<div class="course-wizard-panel">
  <div class="d-flex space-between">
    <h3 class="no-margin-all">API v2</h3>
  </div>

  <div class="d-flex space-between">
    <h4 class="no-margin-all">External applications</h4>
  </div>

  <div *ngIf="loading && !applications?.length">
    <div class="load-spinner"></div>
  </div>

  <div class="course-details-settings">
    <ul class="content-list">
      <li class="content-list-item" *ngIf="!loading && applications.length === 0">
        <div class="alert alert-sm alert-info">No authorized external applications.</div>
      </li>
      <ng-container *ngIf="applications.length > 0">
        <li class="content-list-item" *ngFor="let userApplication of applications">
          <div class="flex">
            <div class="flex-row">
              <div class="flex-col flex-col-grow">
                <a
                  title="View application"
                  [uiSref]="'main.userExternalApplication'"
                  [uiParams]="{ applicationId: userApplication.application.id }"
                  [hidden]="loading"
                  >{{ userApplication.application.name }}</a
                >
                <span
                  title="View application"
                  [hidden]="!loading"
                  aria-disabled="true"
                  class="btn-link disabled-link"
                  >{{ userApplication.application.name }}</span
                >
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
