import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISavedSearchItem } from 'modules/search/models/saved-searches.models';
import { SavedSearchesServive } from 'modules/search/services/saved-searches.service';

@Component({
  standalone: false,
  selector: 'save-search-modal',
  templateUrl: './save-search.component.html',
})
export class SaveSearchComponent {
  notify = false;
  title: string;
  processing: boolean;

  constructor(
    private window: Window,
    private activeModal: NgbActiveModal,
    private savedSearchesServive: SavedSearchesServive,
  ) {}

  submit() {
    const data: Partial<ISavedSearchItem> = {
      name: this.title.trim(),
      searchString: new URLSearchParams(this.window.location.search).toString(),
      notify: this.notify,
    };

    this.processing = true;
    this.savedSearchesServive.save(data).subscribe((item) => {
      this.processing = false;
      this.activeModal.close(item);
    });
  }

  close() {
    this.activeModal.dismiss();
  }
}
