import { Injectable } from '@angular/core';
import { BaseSearchFilterService } from 'app/modules/search/common/services/filters/base-search.service';
import { UrlParamService } from 'core/navigation';
import { LearningSeriesService } from 'modules/learning-series/services/learning-series.service';
import { ISearchFilter, ISearchFilterItem } from 'modules/search/models/search-filters.models';
import { EMPTY, Observable, catchError } from 'rxjs';

@Injectable()
export class LsSearchFilterService extends BaseSearchFilterService {
  public override filter: ISearchFilter = {
    name: 'lsFilter',
    label: 'Learning Series',
    term: 'ls_id',
    mode: 'multiChoice',
    multiChoice: true,
    open: false,
    items: [],

    appearance: 'panel',
    displayLimit: 10,
    visible: false,
  };

  constructor(
    urlService: UrlParamService,
    protected learningSService: LearningSeriesService,
  ) {
    super(urlService);
  }

  public override initSelectedItems(): void {
    const options = Array.from(this.filter.selectedOptions.values() || []).filter(
      (i) => !this.filter.items.find((j) => j.value.toString() === i),
    );

    super.initSelectedItems();

    if (options.length) {
      options.forEach((i) => {
        const learningSeriesId = Number(i);

        if (!isNaN(learningSeriesId)) {
          this.learningSService
            .get(learningSeriesId, true)
            .pipe(catchError(() => this.handleError()))
            .subscribe((ls) => {
              const item: ISearchFilterItem = {
                value: ls.id.toString(),
                text: ls.name || 'Learning Series',
                selected: true,
              };

              this.filter.items.push(item);
              this.filter.selectedItems.push(item);
            });
        }
      });
    }
  }

  private handleError(): Observable<never> {
    this.addGenericItems();

    return EMPTY;
  }
}
