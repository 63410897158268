import { CommonModule, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { UserCoursesComponent } from 'modules/user-learning/components/user-courses.component';
import { UserLearningViewModeSwitcherComponent } from 'modules/user-learning/components/user-learning-view-mode-switcher.component';
import { UserLearningComponent } from 'modules/user-learning/components/user-learning.component';
import { userLearningRoutingModule } from 'modules/user-learning/user-learning-routing.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    NgbNavModule,
    RouterOutlet,
    UIRouterUpgradeModule.forChild({ states: userLearningRoutingModule }),
    NgIf,
    CourseRegistrationCommonModule,
  ],
  declarations: [UserLearningComponent, UserLearningViewModeSwitcherComponent, UserCoursesComponent],
  providers: [],
})
export class UserLearningModule {}
