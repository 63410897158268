import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/core';
import { CurrentUserService } from 'core/authorization';
import { lastValueFrom } from 'rxjs';
import { UserService } from '../services/user.service';
import { UserNetworkComponent } from './components/user-network.component';

export const userNetworkRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.user_network',
    url: '/community/{userId}',
    params: {
      userId: null,
    },
    data: {
      label: 'Community',
      availableFor: ['regularUser', 'admin'],
    },
    component: UserNetworkComponent,
    resolve: [
      {
        provide: 'observedUserId',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().userId;
        },
      },
      {
        provide: 'user',
        deps: [Transition, CurrentUserService, UserService],
        useFactory: (transition: Transition, currentUserService: CurrentUserService, userService: UserService) => {
          const selfUser = currentUserService.get();

          if (!transition.params().userId || parseInt(transition.params().userId, 10) === selfUser.id) {
            return selfUser;
          } else {
            return lastValueFrom(userService.get(parseInt(transition.params().userId, 10))).then(
              (userProfile) => {
                if (userProfile?.userNetwork?.profile?.sharingEnabled) {
                  return userProfile;
                } else {
                  return Promise.reject({ status: 403, data: 'This user is not sharing the profile.' });
                }
              },
              (reason) => {
                if (reason.status === 403) {
                  return Promise.reject({ status: 403, data: 'This user is not sharing the profile.' });
                } else {
                  return Promise.reject(reason);
                }
              },
            );
          }
        },
      },
    ],
  },
];
