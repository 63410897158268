<div>
  <back-button></back-button>

  <div class="row">
    <div class="col-xs-12" [ngClass]="{ 'col-sm-8': leaderboardEnabled && leaderboardUsers?.items.length }">
      <div class="flex flex-vertical-center">
        <div class="flex-row">
          <div class="flex-col flex-col-grow">
            <h3 class="user-achievement-title no-margin-bottom">
              Badges
              <span class="achievement-stats" *ngIf="!simpleTiles"
                >({{ userAchievements.length }}/{{ availableAchievements.length }})</span
              >
              <a
                href="#"
                class="pull-right"
                (click)="showLeaderboard()"
                title="Leaderboard"
                *ngIf="leaderboardEnabled && mobileView">
                Leaderboard
              </a>
            </h3>
          </div>
          <div class="flex-col flex-col-10x text-right" *ngIf="!mobileView && stats?.totalPoints">
            <span class="label label-primary">{{ stats.totalPoints }} points</span>
          </div>
        </div>
      </div>

      <div *ngIf="simpleTiles">
        <hr class="horizontal-divider" />
        <div class="row">
          <div class="col-xs-12">
            <span class="course-title-modern">
              <span *ngIf="!currentUser?.userNetwork || !isStateAvailable('main.user_network')">
                {{ stats.user.firstName }} {{ stats.user.lastName }}
              </span>
              <a
                href="#"
                title="View User Community"
                [attr.id]="trackingName + '_user_link'"
                *ngIf="currentUser?.userNetwork && isStateAvailable('main.user_network')"
                uiSref="main.user_network"
                [uiParams]="{ userId: user.id }">
                {{ user.firstName }} {{ user.lastName }}
              </a>
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="!simpleTiles">
        <span id="progressLabel" class="sr-only">Badges Progressbar</span>
        <ngb-progressbar
          class="achievement-progress d-block"
          type="primary"
          [value]="percentage"
          [max]="100"
          title="progressLabel"></ngb-progressbar>
      </div>

      <div class="f-row" *ngIf="availableAchievements.length">
        <div
          class="f-col-xs-12 user-achievement-wrapper"
          *ngFor="let item of availableAchievements"
          tabindex="0"
          (click)="simpleTiles && openModal(item)"
          (keydown.enter)="simpleTiles && openModal(item)"
          [ngClass]="[
            leaderboardUsers?.items.length ? 'f-col-sm-6' : 'f-col-sm-4',
            simpleTiles ? 'tile-clickable' : '',
          ]">
          <div class="panel panel-default user-achievement-panel">
            <div class="panel-body">
              <div class="user-achievement-image-overlay s0">
                <div
                  class="achievement-badge-mark"
                  *ngIf="(item.endDate && !item.achieved) || item.achieved"
                  [ngClass]="{ 'success-badge': item.achieved, 'timed-badge': item.endDate && !item.achieved }"></div>
              </div>

              <img
                *ngIf="!item.achieved"
                src="{{ '/a/media/file/' + item.badgeFile.id + '/?rnd=' + item.badgeFile.rnd }}"
                class="image-achievement sm-margin-bottom user-achievement-image"
                alt="" />
              <img
                *ngIf="item.achieved"
                src="{{ '/a/media/file/' + item.achieved.badgeFile.id + '/?rnd=' + item.achieved.badgeFile.rnd }}"
                class="image-achievement sm-margin-bottom user-achievement-image"
                alt="" />

              <span *ngIf="leaderboardEnabled">
                <span class="user-achievement-points" *ngIf="item.achieved">
                  <span
                    >{{ item.achieved.pointValue || 0 }} {{ item.achieved.pointValue > 1 ? 'points' : 'point' }}</span
                  >
                </span>
                <span class="user-achievement-points" *ngIf="!item.achieved">
                  <span>{{ item.points || 0 }} {{ item.points > 1 ? 'points' : 'point' }}</span>
                </span>
              </span>
              <h4 class="achievement-title">{{ item.message }}</h4>

              <div *ngIf="item.achievementTypeId === 7 || item.achieved?.achievementTypeId === 7">
                <div>
                  <span *ngIf="item.achieved"
                    >Complete
                    <strong class="font-semi-bold">
                      <span
                        >{{ item.achieved.threshold || 0 }} {{ item.achieved.threshold > 1 ? 'hours' : 'hour' }}</span
                      >
                    </strong></span
                  >
                  <span *ngIf="item.achieved"
                    >Complete
                    <strong class="font-semi-bold">
                      <span>{{ item.threshold || 0 }} {{ item.threshold > 1 ? 'hours' : 'hour' }}</span>
                    </strong></span
                  >
                </div>
              </div>

              <div *ngIf="item.achievementTypeId === 8 || item.achieved?.achievementTypeId === 8">
                <div>
                  <span *ngIf="item.achieved"
                    >Complete any
                    <strong class="font-semi-bold">
                      <span>{{ item.threshold || 0 }} {{ item.threshold > 1 ? 'courses' : 'course' }}</span>
                    </strong></span
                  >
                  <span *ngIf="!item.achieved"
                    >Complete any
                    <strong class="font-semi-bold">
                      <span>{{ item.threshold || 0 }} {{ item.threshold > 1 ? 'courses' : 'course' }}</span>
                    </strong></span
                  >
                </div>
              </div>

              <div
                *ngIf="
                  item.achievementTypeId === 9 ||
                  item.achieved?.achievementTypeId === 9 ||
                  item.achievementTypeId === 10 ||
                  item.achieved?.achievementTypeId === 10
                ">
                <span *ngIf="!item.achieved && item.groupAchievementCourses?.length === 1"
                  >Complete
                  <a
                    uiSref="main.course"
                    [uiParams]="{ id: item.groupAchievementCourses[0].courseId }"
                    href="#"
                    title="Go to {{ item.groupAchievementCourses[0].name }} course"
                    [attr.id]="trackingName + '_course_link1'">
                    {{ item.groupAchievementCourses[0].name }}
                  </a>
                </span>
                <span *ngIf="!item.achieved && item.groupAchievementCourses?.length > 1"
                  >Complete
                  <a
                    href="#"
                    (click)="openModal(item)"
                    title="Go to achievement details"
                    [attr.id]="trackingName + '_course_link1'">
                    {{ item.groupAchievementCourses.length }} courses
                  </a>
                </span>
                <span *ngIf="item.achieved?.objectData.courses?.length === 1"
                  >Complete
                  <a
                    uiSref="main.course"
                    [uiParams]="{ id: item.achieved.objectData.courses[0].courseId }"
                    href="#"
                    title="Go to {{ item.achieved.objectData.courses[0].courseName }} course"
                    [attr.id]="trackingName + '_course_link1'">
                    {{ item.achieved.objectData.courses[0].courseName }}
                  </a>
                </span>
                <span *ngIf="item.achieved?.objectData.courses?.length > 1"
                  >Complete
                  <a
                    href="#"
                    (click)="openModal(item)"
                    title="Go to achievement details"
                    [attr.id]="trackingName + '_course_link1'">
                    {{ item.achieved.objectData.courses.length }} courses
                  </a>
                </span>
              </div>

              <div *ngIf="!item.achieved">
                <div class="mt-5 mb-5" *ngIf="item.startDate && item.endDate">
                  between <span>{{ item.startDate | date: 'MMM d, y' }}</span> and
                  <span>{{ item.endDate | date: 'MMM d, y' }}</span>
                </div>
                <div class="mt-5 mb-5" *ngIf="item.endDate && !item.startDate">
                  by <span>{{ item.endDate | date: 'MMM d, y' }}</span>
                </div>

                <div class="mt-5 mb-5" *ngIf="item.startDate && !item.endDate">
                  after <span>{{ item.startDate | date: 'MMM d, y' }}</span>
                </div>
              </div>
            </div>

            <div class="panel-footer" *ngIf="!simpleTiles">
              <button
                type="button"
                class="btn btn-block"
                [ngClass]="item.achieved ? 'btn-default' : 'btn-outline'"
                [disabled]="uiBlocker"
                [attr.id]="trackingName + '_details_button'"
                (click)="openModal(item)">
                <span *ngIf="!item.achieved">View Progress</span>
                <span *ngIf="item.achieved"
                  >Achieved
                  <span *ngIf="item.achieved.createdDate">
                    on {{ item.achieved.createdDate | date: 'mediumDate' }}</span
                  >
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-xs-12" *ngIf="!mobileView && leaderboardEnabled && leaderboardUsers?.items.length">
      <achievements-leaderboard [user]="user" [users]="leaderboardUsers"></achievements-leaderboard>
    </div>
  </div>
</div>
