<div class="row" *ngIf="stateService.is('main.leaderboard')">
  <div class="col-xs-12">
    <div class="pull-left">
      <a href="" (click)="backUrlService.goBack()" class="btn btn-link no-padding-left modern-back-button">
        <i class="fa fa-chevron-left"></i>Back
      </a>
    </div>
  </div>
</div>

<h3 class="user-achievement-title">
  <a href="#" (click)="showBadges()" title="Badges" *ngIf="mobileView">Badges</a>
  <span [ngClass]="{ 'pull-right': mobileView }">Leaderboard</span>
</h3>

<div class="leaderboard-last-updated" *ngIf="users?.items.length">
  Last updated on {{ users.items[0].updated | date: 'mediumDate' }}
</div>

<div *ngIf="users?.items.length">
  <ul class="content-list">
    <li
      class="leaderboard-tile"
      *ngFor="let item of users.items; let i = index"
      [ngClass]="{
        'leaderboard-highlight': item.user.id === user.id,
        'leaderboard-has-divider': i === chunkSize - 1 && users.divider,
      }">
      <button type="button" class="btn-text w-100" (click)="viewUserBadges(item.user.id)">
        <div class="fake-border" *ngIf="item.user.id === user.id">&nbsp;</div>
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col leaderboard-rank">{{ item.rowNumber }}</div>

            <div class="flex-col flex-col-3x leaderboard-avatar-placeholder">
              <user-avatar [user]="item.user" [size]="32" [hideTitle]="true"></user-avatar>

              <span class="leaderboard-delta-force" *ngIf="item.rankDelta">
                <span [ngClass]="{ down: item.rankDelta < 0, up: item.rankDelta > 0 }">
                  <span
                    class="fa"
                    [ngClass]="{ 'fa-caret-down': item.rankDelta < 0, 'fa-caret-up': item.rankDelta > 0 }"
                    aria-hidden="true"></span>
                  {{ item.rankDelta }}
                </span>
              </span>
            </div>

            <div class="flex-col flex-col-grow xs-margin-left">
              {{ item.user.firstName + ' ' + item.user.lastName }}
              <span *ngIf="item.user.id === currentUser.id">(you)</span>
            </div>

            <div class="flex-col leaderboard-points">
              {{ item.totalPoints }}
            </div>
          </div>
        </div>
      </button>
    </li>
  </ul>
</div>
