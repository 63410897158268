import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IExternalApplication } from 'modules/external-applications/models/external-application.model';
import { IExternalApplicationKey } from 'modules/user/external-applications/models/external-application-key.model';
import { ExternalApplicationKeyService } from 'modules/user/external-applications/services/external-application-key.service';
import moment from 'moment/moment';
import { finalize } from 'rxjs';

@Component({
  standalone: false,
  selector: 'add-external-application-key-modal',
  templateUrl: 'add-external-application-key-modal.component.html',
})
export class AddExternalApplicationKeyModalComponent {
  @Input() application: IExternalApplication;
  loading = false;
  expirationDate: Date;
  key: IExternalApplicationKey;
  currentDate = moment().format('YYYY-MM-DD');

  constructor(
    private activeModal: NgbActiveModal,
    private keyService: ExternalApplicationKeyService,
    private currentUser: CurrentUserService,
  ) {}

  generateKey(form: FormGroup): void {
    if (form.valid) {
      this.loading = true;

      this.keyService
        .generateKey(
          this.application.id,
          this.currentUser.get().id,
          this.expirationDate ? moment(this.expirationDate).format('YYYY-MM-DD') : null,
        )
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((key) => {
          this.activeModal.close(key);
        });
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
