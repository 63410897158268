import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchFilter, ISearchFilterService } from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';

@Component({
  standalone: false,
  selector: 'price-filter',
  templateUrl: './price-filter.component.html',
})
export class PriceFilterComponent implements OnInit, OnDestroy {
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Input() filterService: ISearchFilterService;

  filter: ISearchFilter;
  status = { isOpen: false, changed: false, free: false, paid: false, minPrice: null, maxPrice: null };

  private subscriber: Unsubscribable;

  ngOnInit(): void {
    this.filter = this.filterService.filter;
    this.subscriber = this.filterService.conditionsChanged().subscribe(() => this.ngOnConditionsChanged());
  }

  ngOnDestroy(): void {
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnConditionsChanged() {
    this.filterService.restoreDefaults();
    this.initValues();
  }

  private initValues() {
    this.status.paid =
      !!this.filter.items[0].value ||
      !!this.filter.items[1].value ||
      Number(this.filter.items[0].value) === 0 ||
      Number(this.filter.items[1].value) === 0;

    if (this.status.paid) {
      this.status.minPrice = this.filter.items[0].value;
      this.status.maxPrice = this.filter.items[1].value;
    }

    this.status.free = !!this.filter.items[2].value;
  }
}
