import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { Observable } from 'rxjs';

export interface IToken {
  token: string;
}

export enum ApiAccessEndpoints {
  POST = '/a/user/:id/authorization-token/',
}

@Injectable()
export class ApiAccessService {
  constructor(private http: HttpClient) {}

  generateAuthorizationToken(id: number, password: string, throwHttpErrors = false): Observable<IToken> {
    return this.http.post<IToken>(
      ElmsUtils.formatUrl(ApiAccessEndpoints.POST, { id }),
      { password },
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
    );
  }
}
