import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequestParams } from 'core/services';
import { ElmsUtils } from 'core/utils';
import { Observable, map } from 'rxjs';
import * as uuid from 'uuid';
import { IResource, IResourcePayload } from '../models/resource.model';

export enum ResourceEndpoints {
  QUEUE = '/a/resources/',
  GET = '/a/resources/:resourceId/',
  PUT = '/a/resources/:resourceId/',
  POST = '/a/resources/',
}

export interface IQueueResponse<T> {
  count: number;
  items: T[];
}

@Injectable()
export class ResourceService {
  private defaultProps: Partial<IResource> = {
    active: true,
    visible: true,
    globalId: uuid.v4(),
    contentType: 'file',
    expirationDate: null,
  };

  constructor(private http: HttpClient) {}

  public get(resourceId: number, permissions?: string[]): Observable<IResource> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http
      .get<IResource>(ElmsUtils.formatUrl(ResourceEndpoints.GET, { resourceId }), { params: params })
      .pipe(
        map((resource) =>
          Object.assign({}, this.defaultProps, resource, { contentType: resource.url ? 'url' : 'file' }),
        ),
      );
  }

  public post(payload: Partial<IResourcePayload>): Observable<IResource> {
    return this.http.post<IResource>(ResourceEndpoints.POST, payload);
  }

  public update(resourceId: number, payload: Partial<IResourcePayload>): Observable<IResource> {
    return this.http.put<IResource>(ResourceEndpoints.PUT.replace(':resourceId', resourceId.toString()), payload);
  }

  public delete(resourceId: number): Observable<IResource> {
    return this.http.delete<IResource>(ResourceEndpoints.GET.replace(':resourceId', resourceId.toString()));
  }

  public queue(params: HttpRequestParams): Observable<IQueueResponse<IResource>> {
    return this.http.get<IQueueResponse<IResource>>(ResourceEndpoints.QUEUE, { params });
  }

  public newResource(resourceAttr?: Partial<IResource>): Partial<IResource> {
    return {
      ...this.defaultProps,
      ...(resourceAttr || {}),
    };
  }
}
