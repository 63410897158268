import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { IQuestion } from 'modules/quiz/models/quiz.model';
import { Observable, map } from 'rxjs';
import * as uuid from 'uuid';

enum QuizQuestionEndpoints {
  GET = '/a/quizzes/questions/:questionId/',
  PUT = '/a/quizzes/questions/:questionId/',
  POST = '/a/quizzes/questions/',
}

@Injectable()
export class QuizQuestionService {
  constructor(private http: HttpClient) {}

  get defaultProps(): Partial<IQuestion> {
    return {
      globalId: uuid.v4(),
      attachments: [],
      active: true,
      answerVariants: [],
      optional: false,
    };
  }

  public get(questionId: number | string, permissions?: string[], throwHttpErrors = false): Observable<IQuestion> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http
      .get<IQuestion>(ElmsUtils.formatUrl(QuizQuestionEndpoints.GET, { questionId }), {
        params,
        context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
      })
      .pipe(
        map((question) => {
          return Object.assign({}, this.defaultProps, question);
        }),
      );
  }

  public post(payload: Partial<IQuestion>, weight?: number): Observable<IQuestion> {
    let params = new HttpParams();

    if (weight) {
      params = params.set('weight', weight);
    } else if (payload.weight) {
      params = params.set('weight', payload.weight);
    }

    return this.http.post<IQuestion>(QuizQuestionEndpoints.POST, payload, { params });
  }

  public update(questionId: number, payload: Partial<IQuestion>): Observable<IQuestion> {
    return this.http.put<IQuestion>(ElmsUtils.formatUrl(QuizQuestionEndpoints.PUT, { questionId }), payload);
  }

  public delete(questionId: number | string, throwHttpErrors = false): Observable<null> {
    return this.http.delete<null>(ElmsUtils.formatUrl(QuizQuestionEndpoints.GET, { questionId }), {
      context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors),
    });
  }

  public newQuestion(attrs?: Partial<IQuestion>): Partial<IQuestion> {
    return {
      ...this.defaultProps,
      ...(attrs || {}),
    };
  }
}
