<div ngbDropdown placement="bottom-start" class="btn-group dropdown">
  <button
    ngbDropdownToggle
    type="button"
    title="Saved searches"
    class="btn btn-default dropdown-toggle"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="open()"
    [disabled]="processing">
    <span class="fa fa-bookmark"></span><span class="sr-only">View your saved searches</span>
  </button>

  <div ngbDropdownMenu class="dropdown-menu">
    <div class="load-spinner" *ngIf="processing"></div>
    <div class="dropdown-menu-truncated" *ngFor="let item of searches">
      <a
        href="#"
        ngbDropdownItem
        class="no-text-decoration w-100 button"
        [uiSref]="'main.search'"
        [uiParams]="item.params"
        [uiOptions]="{ inherit: false }">
        {{ item.name }}
      </a>
    </div>
    <div class="dropdown-divider" *ngIf="savedSearches?.length" aria-hidden="true"></div>
    <button
      ngbDropdownItem
      type="button"
      class="button"
      title="Manage Saved Searches"
      *ngIf="savedSearches?.length"
      (click)="managedSavedSearches()">
      Manage Saved Searches
    </button>
    <div class="dropdown-divider" *ngIf="savedSearches?.length && !isAnonymous" aria-hidden="true"></div>
    <button
      ngbDropdownItem
      type="button"
      class="button"
      title="Save Search"
      *ngIf="!processing && !isAnonymous"
      (click)="saveSearch()">
      Save Search
    </button>
  </div>
</div>
