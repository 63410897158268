<div class="d-flex space-between">
  <h3 class="no-margin-all">API Keys</h3>
  <button type="button" title="Add key" class="btn btn-default btn-admin-details" (click)="addKey()">Add</button>
</div>

<div class="course-details-settings">
  <div class="content-panel" *ngIf="newKey">
    <p>
      <strong>Note:</strong>
      <span class="text-muted"
        >You need to save both keys in order to use them in API authorization. It is impossible to see or recover the
        API key secret; you can only see it when creating a key.</span
      >
    </p>
    <div class="sm-margin-bottom">
      <strong>API key</strong>
      <div class="d-flex gap-5 align-items-center">
        <div class="text-overflow-ellipsis">{{ newKey.key }}</div>
        <button
          type="button"
          (click)="copyAccessToken()"
          title="Copy to clipboard"
          class="inline-block sm-margin-left btn btn-text btn-lg">
          <span class="fa fa-clipboard" aria-hidden="true"></span>
          <span class="sr-only">Copy</span>
        </button>
      </div>
    </div>

    <div class="sm-margin-bottom">
      <strong>API key secret</strong>
      <div class="input-group">
        <input [type]="secretKeyInputType" id="key" class="form-control" value="{{ newKey.secretPlain }}" readonly />
        <span class="input-group-btn">
          <button type="button" class="btn btn-default" (click)="toggleSecretKeyVisibility()">
            <i
              class="fa"
              aria-hidden="true"
              [ngClass]="{
                'fa-eye': secretKeyInputType === 'password',
                'fa-eye-slash': secretKeyInputType === 'text',
              }"></i>
            <span class="sr-only">Reveal/hide secret key</span>
          </button>
          <button type="button" class="btn btn-default" (click)="copyKeySecret()">
            <i class="fa fa-clipboard" aria-hidden="true"></i>
            <span class="sr-only">Copy key to clipboard</span>
          </button>
        </span>
      </div>
    </div>
    <div class="mb-25">
      <strong>Expires</strong>
      <div>
        {{ newKey.expires ? getExpirationPeriod(newKey) : 'Never' }}
      </div>
    </div>

    <fieldset name="examplesFields">
      <legend>Example</legend>
      <div class="row">
        <div class="col-sm-1">
          <strong>Curl:</strong>
        </div>
        <div class="col-sm-10">
          <code class="d-block">
            <div class="text-overflow-ellipsis">curl -X GET "{{ request_url }}" \</div>
            <div class="margin-left-2x text-overflow-ellipsis">-H "accept: application/json" \</div>
            <div class="margin-left-2x text-overflow-ellipsis">-H "X-API-KEY: {{ newKey.key }}" \</div>
            <div class="margin-left-2x text-overflow-ellipsis">-H "X-API-SECRET: <strong>API_KEY_SECRET</strong>"</div>
          </code>
        </div>
      </div>
    </fieldset>
  </div>

  <div *ngIf="loading && !apiKeys?.length">
    <div class="load-spinner"></div>
  </div>

  <ul class="content-list mt-10" *ngIf="apiKeys.length" [ngClass]="{ 'disabled-text': loading }">
    <li class="content-list-header">
      <div class="flex">
        <div class="flex-row">
          <div class="flex-col flex-col-grow">
            <strong>Key</strong>
          </div>
          <div class="flex-col flex-col-10x hidden-xs">
            <strong>Created</strong>
          </div>
          <div class="flex-col flex-col-8x">
            <strong>Expires</strong>
          </div>
          <div class="flex-col flex-col-10x hidden-xs">
            <strong>Last used</strong>
          </div>
          <div class="flex-col flex-col-2x"></div>
        </div>
      </div>
    </li>

    <li class="content-list-item" *ngFor="let key of apiKeys">
      <div class="flex">
        <div class="flex-row">
          <div class="flex-col flex-col-grow text-overflow-ellipsis">
            {{ key.key }}
          </div>
          <div class="flex-col flex-col-10x hidden-xs">
            <date-with-hidden-time [date]="key.created"></date-with-hidden-time>
          </div>
          <div class="flex-col flex-col-8x">
            <span *ngIf="expired(key)" [ngbTooltip]="getExpirationPeriod(key)">Expired</span>
            <span *ngIf="!expired(key) && key.expires">{{ getExpirationPeriod(key) }}</span>
          </div>
          <div class="flex-col flex-col-10x hidden-xs">
            <date-with-hidden-time [date]="key.lastUsed" *ngIf="key.lastUsed"></date-with-hidden-time>
            <span *ngIf="!key.lastUsed">Never</span>
          </div>
          <div class="flex-col flex-col-2x">
            <div ngbDropdown placement="bottom-end auto">
              <span>
                <button
                  type="button"
                  title="Actions"
                  href="#"
                  class="btn btn-link dot-menu-large"
                  ngbDropdownToggle
                  [disabled]="loading">
                  <span class="fa fa-ellipsis-v" aria-label="Open menu">&nbsp;</span>
                </button>
              </span>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <a
                  title="Delete key"
                  ngbDropdownItem
                  (clickConfirmation)="deleteKey(key, $event)"
                  [clickConfirmationSubscriber]="true"
                  clickConfirmationMessage="Delete key? This action cannot be undone."
                  clickConfirmationButtonOk="Delete"
                  clickConfirmationTitle="Delete key"
                  ><span class="text-danger">Delete</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex-row visible-xs">
          <div class="flex-col flex-col-grow">
            <div>Created: <date-with-hidden-time [date]="key.created"></date-with-hidden-time></div>
            <div>
              Last used: <date-with-hidden-time [date]="key.lastUsed" *ngIf="key.lastUsed"></date-with-hidden-time>
              <span *ngIf="!key.lastUsed">Never</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
