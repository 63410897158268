<div class="row">
  <div class="col-xs-12">
    <div [innerHtml]="question.text | markdownToHtml"></div>
  </div>

  <div class="col-xs-12" *ngIf="question.image?.id || question.tmpImageFile">
    <!--Question image. Server image handler or locally stored file which will be available on question preview.-->
    <div class="img-question-wrapper">
      <img
        class="img-question"
        *ngIf="question.image?.id"
        alt="Question image"
        src="{{ '/a/media/file/' + question.image.id + '/?rnd=' + question.image.rnd }}" />
      <thumbnail
        class="thumbnail-image"
        *ngIf="question.tmpImageFile"
        [file]="tmpFiles[question.tmpImageFile.tmpName].file"
        [params]="{ width: 120, height: 120 }"></thumbnail>
    </div>
  </div>
</div>

<!--answer row-->

<div class="row">
  <div class="col-xs-12 standard-margin-top">
    <fieldset [disabled]="!quizResult || quizResultService.isCompleted(quizResult)">
      <ul *ngIf="question.answerVariants?.length && question.typeId !== quizQuestionType.matching" class="list-group">
        <li
          *ngFor="let answerVariant of question.answerVariants; let $index = index"
          class="list-group-item question-choice"
          [ngClass]="{
            'list-group-item-success': isSuccessAnswer(answerVariant),
            'list-group-item-danger': isFailedAnswer(answerVariant),
          }">
          <label [for]="'answer-' + $index" class="no-margin-bottom display-block use-hand-cursor">
            <!--Multichoice START-->
            <div
              *ngIf="
                question.typeId === quizQuestionType.multiChoice ||
                question.typeId === quizQuestionType.checkAllThatApply ||
                question.typeId === quizQuestionType.shortAnswer
              ">
              <div class="flex flex-vertical-center">
                <div class="flex-row">
                  <!-- checkbox\radio-->
                  <div class="flex-col flex-col-2x use-hand-cursor">
                    <!--checkAllThatApply input-->
                    <input
                      type="checkbox"
                      [name]="'cb_' + question.id"
                      *ngIf="question.typeId === quizQuestionType.checkAllThatApply"
                      [(ngModel)]="answerVariant.selected"
                      [id]="'answer-' + $index" />
                    <!--multiChoice input-->
                    <input
                      type="radio"
                      [name]="'rb_' + question.id"
                      [(ngModel)]="question.selectedChoice"
                      (ngModelChange)="choiceChecked(answerVariant)"
                      [value]="answerVariant"
                      *ngIf="question.typeId === quizQuestionType.multiChoice"
                      [id]="'answer-' + $index" />
                  </div>

                  <div
                    class="flex-col flex-col-grow"
                    *ngIf="
                      question.typeId === quizQuestionType.checkAllThatApply ||
                      question.typeId === quizQuestionType.multiChoice
                    ">
                    <!--hide badge by default -->
                    <span
                      class="badge question-mark-badge display-none"
                      *ngIf="question.typeExtensionId === questionTypeExtension.likertScale.id">
                      {{ question.answerVariants?.length - $index }}
                    </span>

                    <div
                      class="font-normal no-margin-bottom"
                      *ngIf="question.typeId === quizQuestionType.checkAllThatApply">
                      <span [innerHtml]="answerVariant.text | markdownToHtml"></span>
                    </div>
                    <div class="font-normal no-margin-bottom" *ngIf="question.typeId === quizQuestionType.multiChoice">
                      <span [innerHtml]="answerVariant.text | markdownToHtml"></span>
                    </div>
                  </div>
                </div>
                <!--end row-->
                <div class="flex-row">
                  <div
                    class="flex-col flex-col-grow pull-left"
                    *ngIf="answerVariant.otherRequired && answerVariant.selected">
                    <input
                      type="text"
                      required
                      class="form-control required xs-margin-top font-normal"
                      [name]="'answer-other-' + $index"
                      maxlength="1000"
                      autoFocus
                      [(ngModel)]="answerVariant.other"
                      id="answer-other-{{ $index }}" />
                  </div>
                  <div class="flex-col flex-col-grow pull-left" *ngIf="answerVariant.otherRequired && !quizResult">
                    <input
                      type="text"
                      required
                      class="form-control required xs-margin-top font-normal"
                      [name]="'answer-other-' + $index"
                      maxlength="1000"
                      [(ngModel)]="answerVariant.other"
                      id="answer-other-{{ $index }}" />
                  </div>
                </div>
                <!--end row-->
                <div class="flex-row">
                  <div
                    class="flex-col flex-col-grow sm-margin-top"
                    *ngIf="answerVariant.image?.id || answerVariant.tmpImageFile">
                    <!--Question Choice image. Server image handler or locally stored
                    file which will be available on question preview.-->
                    <div *ngIf="answerVariant.image?.id" class="img-question-wrapper">
                      <img
                        class="img-question"
                        alt="Answer image"
                        src="/a/media/file/{{ answerVariant.image.id }}/?rnd={{ answerVariant.image.rnd }}" />
                    </div>
                    <thumbnail
                      class="thumbnail-image"
                      *ngIf="answerVariant.tmpImageFile"
                      [file]="tmpFiles[answerVariant.tmpImageFile.tmpName].file"
                      [params]="{ width: 100, height: 120 }"></thumbnail>
                  </div>
                </div>
              </div>
            </div>
            <!--end media-->
            <!--Multichoice END-->

            <!--Fill In START-->
            <div *ngIf="question.typeId === quizQuestionType.fillin" class="font-normal">
              <span *ngIf="question.answerVariants?.length > 1">{{ $index + 1 }}. </span>

              <div *ngIf="quizResult">
                <div
                  [ngClass]="{ 'has-error': fillinVariants.invalid && fillinVariants.dirty }"
                  *ngIf="
                    (!quizResultService.isCompleted(quizResult) || quizResult?.quiz.showCorrectAnswers === 0) &&
                    !(!quizResult || quizResultService.isCompleted(quizResult))
                  ">
                  <markdown-editor-component
                    [(ngModel)]="answerVariant.answerText"
                    [disableAttachments]="true"
                    #fillinVariants="ngModel"
                    [hideButtons]="['urlLinkButton', 'imageButton']"
                    [required]="true"
                    [maxLength]="100"
                    [id]="'answer-' + $index"></markdown-editor-component>

                  <p
                    class="help-block"
                    *ngIf="fillinVariants.invalid && fillinVariants.dirty"
                    style="padding-top: 10px">
                    <span *ngIf="fillinVariants.errors?.required"
                      >The answer is required and the max length is 100 symbols.</span
                    >
                  </p>
                </div>
                <div
                  id="answer-{{ $index }}"
                  [innerHtml]="answerVariant.answerText | markdownToHtml"
                  *ngIf="quizResultService.isCompleted(quizResult) && !showQuestionCorrectness()"></div>
              </div>

              <div *ngIf="!quizResult">
                <textarea
                  type="text"
                  id="answer-{{ $index }}"
                  class="form-control required"
                  style="resize: none"
                  rows="4"></textarea>
              </div>

              <div
                *ngIf="
                  quizResult?.quiz.scoreTypeId && quizResultService.isCompleted(quizResult) && showQuestionCorrectness()
                ">
                <div
                  [ngClass]="{
                    'alert alert-success': !!answerVariant.correct,
                    'alert alert-danger': answerVariant.correct === false,
                  }">
                  <strong>Your Answer:</strong> <span [innerHtml]="answerVariant.answerText | markdownToHtml"></span>
                </div>
                <div class="alert alert-success" *ngIf="!!answerVariant.correctAnswers">
                  <b>Correct Answer(s):</b>
                  <span [innerHtml]="answerVariant.correctAnswers | markdownToHtml"></span>
                </div>
              </div>
            </div>
            <!--Fill In END-->
          </label>
        </li>
      </ul>

      <!-- matching-->
      <div
        *ngIf="question.answerVariants?.length && question.typeId === quizQuestionType.matching"
        class="matching-question-wrapper">
        <div
          class="matching-question-tile"
          *ngFor="let answerVariant of question.answerVariants"
          [ngClass]="{
            'w-50': question.answerVariants.length === 2,
            'w-33': question.answerVariants.length === 3,
            'w-25': question.answerVariants.length > 3,
          }">
          <!--Matching START-->
          <div
            class="panel panel-default"
            [ngClass]="{
              'panel-success bg-success':
                (quizResult?.quiz.scoreTypeId || scoreTypeId) &&
                quizResultService.isCompleted(quizResult) &&
                answerVariant.correct,
              'panel-danger bg-danger':
                (quizResult?.quiz.scoreTypeId || scoreTypeId) &&
                quizResultService.isCompleted(quizResult) &&
                answerVariant.correct === false,
            }">
            <div class="panel-body">
              <div class="" *ngIf="answerVariant.image?.id || answerVariant.tmpImageFile">
                <!--Question Choice image.
                Server image handler or locally stored file which will be available on question preview.-->
                <div *ngIf="answerVariant.image.id" class="thumbnail-control pull-center">
                  <img
                    class="img-question img-matching"
                    alt="Answer image"
                    src="/a/media/file/{{ answerVariant.image.id }}/?rnd={{ answerVariant.image.rnd }}" />
                </div>

                <thumbnail
                  class="thumbnail-image text-center pull-center"
                  *ngIf="answerVariant.tmpImageFile"
                  [file]="tmpFiles[answerVariant.tmpImageFile.tmpName].file"
                  [params]="{ width: 175, height: 175 }"></thumbnail>
              </div>
              <div><span [innerHtml]="answerVariant.text | markdownToHtml"></span></div>

              <hr class="xs-margin-top sm-margin-bottom" />
              <select-dropdown
                class="answer-select-dropdown"
                *ngIf="quizResult"
                [(model)]="answerVariant.answerText"
                (handleChange)="matchingChose(answerVariant)"
                [options]="question.answerTexts"
                selectedText="Choose an Answer">
              </select-dropdown>

              <div class="bg-success text-success" *ngIf="!quizResult">
                <div style="border: 1px solid #ccc; padding: 6px 12px; font-size: 16px; line-height: 1.25">
                  <div class="flex flex-no-padding">
                    <div class="flex-row">
                      <div class="flex-col flex-col-grow">
                        <div
                          class="text-truncate text-left"
                          [innerHtml]="answerVariant.answerText | markdownToHtml"></div>
                      </div>
                      <div class="flex-col flex-col-1x">
                        <div class="text-right">
                          <span class="fa fa-sort"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="quizResult?.quiz.scoreTypeId && quizResultService.isCompleted(quizResult)">
                <div *ngIf="answerVariant.correct === null" class="well well-sm">
                  <div>
                    <span [innerHtml]="answerVariant.answerText | markdownToHtml"></span>
                  </div>
                </div>
                <div *ngIf="answerVariant.correct !== null" class="well well-sm">
                  <div><strong>Your Answer</strong></div>
                  <div
                    [ngClass]="{
                      'text-success': answerVariant.correct,
                      'text-danger': answerVariant.correct === false,
                    }">
                    <span [innerHtml]="answerVariant.answerText | markdownToHtml"></span>
                  </div>
                  <div class="sm-margin-top"><strong>Correct Answer</strong></div>
                  <div class="text-success">
                    <span [innerHtml]="answerVariant.correctAnswer | markdownToHtml"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Matching END-->
        </div>
      </div>

      <!--shortAnswer input-->
      <div *ngIf="question.typeId === quizQuestionType.shortAnswer" class="form-group">
        <label for="answer-control">Answer:</label>
        <textarea
          id="answer-control-quiz-result"
          [(ngModel)]="question.answer"
          rows="5"
          class="form-control"
          maxlength="1000"
          style="resize: vertical"
          *ngIf="quizResult && question.typeId === quizQuestionType.shortAnswer"></textarea>
        <textarea
          id="answer-control"
          [(ngModel)]="question.answer"
          rows="5"
          class="form-control"
          style="resize: none"
          maxlength="1000"
          *ngIf="!quizResult && question.typeId === quizQuestionType.shortAnswer"></textarea>
      </div>

      <!--Question answer validity-->
      <div
        *ngIf="quizResult?.quiz.scoreTypeId && quizResultService.isCompleted(quizResult) && question.correct !== null">
        <!--Feedback-->
        <div class="question-choice" *ngIf="question.feedback && question.feedback.trim()">
          <div class="alert alert-warning">
            <span [innerHtml]="question.feedback | markdownToHtml"></span>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
