import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { LmsCoreModule } from 'core/core.module';
import { ExternalApplicationService } from 'modules/external-applications/services/external-application.service';
import { ApiV1KeyManagementComponent } from 'modules/user/external-applications/api-v1/components/api-v1-key-management.component';
import { ApiV1KeyEditConfirmationModalComponent } from 'modules/user/external-applications/api-v1/components/modal/api-v1-key-edit-confirmation-modal.component';
import { ApiAccessService } from 'modules/user/external-applications/api-v1/services/api-access.service';
import { UserAccessKeysViewComponent } from 'modules/user/external-applications/components/user-access-keys-view.component';
import { AddExternalApplicationKeyModalComponent } from 'modules/user/external-applications/edit/components/modal/add-external-application-key-modal.component';
import { ExternalApplicationKeyService } from 'modules/user/external-applications/services/external-application-key.service';
import { UserExternalApplicationService } from 'modules/user/external-applications/services/user-external-application.service';
import { userExternalApplicationsRoutingModule } from 'modules/user/external-applications/user-external-applications-routing.module';
import { ExternalApplicationKeysComponent } from 'modules/user/external-applications/view/components/external-application-keys.component';
import { ExternalApplicationUserViewComponent } from 'modules/user/external-applications/view/components/external-application-user-view.component';
import { UserExternalApplicationsListComponent } from 'modules/user/external-applications/view/components/user-external-applications-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LmsComponentsModule,
    UIRouterUpgradeModule.forChild({ states: userExternalApplicationsRoutingModule }),
    HttpClientModule,
    NgbDropdownModule,
    LmsCoreModule,
    NgbTooltip,
  ],
  declarations: [
    ApiV1KeyManagementComponent,
    ApiV1KeyEditConfirmationModalComponent,
    UserExternalApplicationsListComponent,
    ExternalApplicationKeysComponent,
    ExternalApplicationUserViewComponent,
    UserAccessKeysViewComponent,
    AddExternalApplicationKeyModalComponent,
  ],
  providers: [
    ApiAccessService,
    ExternalApplicationService,
    ExternalApplicationKeyService,
    UserExternalApplicationService,
  ],
})
export class UserExternalApplicationsModule {}
