import { Component, Input, OnInit } from '@angular/core';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { MetaService } from 'core/services/meta.service.ajs-upgraded-provider';
import { ElmsUtils } from 'core/utils';
import { IResource } from '../models/resource.model';

@Component({
  standalone: false,
  selector: 'resource-details',
  templateUrl: './resource-details.component.html',
})
export class ResourceDetailsComponent implements OnInit {
  @Input() resource!: IResource;

  editable: boolean;

  constructor(
    private globalConfig: GlobalConfig,
    private backUrlService: BackUrlService,
    private metaService: MetaService,
  ) {}

  ngOnInit() {
    this.editable = this.globalConfig.settings.view === 'admin' && this.resource.permissions?.includes('resource.edit');

    this.metaService.title(this.resource.title);
  }

  goBack() {
    this.backUrlService.goBack();
  }

  formatFileSize(bytes: number): string {
    return ElmsUtils.formatBytes(bytes);
  }
}
