<div [ngSwitch]="filterService.filter.mode">
  <single-choice-filter
    *ngSwitchCase="'singleChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></single-choice-filter>
  <multi-choice-filter
    *ngSwitchCase="'multiChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></multi-choice-filter>
  <searchable-multi-choice-filter
    *ngSwitchCase="'searchableMultiChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></searchable-multi-choice-filter>
  <dates-choice-filter
    *ngSwitchCase="'datesChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></dates-choice-filter>
  <popup-multi-choice-filter
    *ngSwitchCase="'popupMultiChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></popup-multi-choice-filter>
  <number-choice-filter
    *ngSwitchCase="'numberChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></number-choice-filter>
  <prompting-filter
    *ngSwitchCase="'promptingFilter'"
    [filterService]="filterService"
    [isMultiple]="true"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></prompting-filter>
  <prompting-filter
    *ngSwitchCase="'singlePromptFilter'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></prompting-filter>
  <boolean-filter
    *ngSwitchCase="'booleanFilter'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></boolean-filter>
  <!-- Did not find any filter with 'mode': 'composite' in the entire project  -->
  <!-- <composite-filter *ngSwitchCase="'composite'" [filterService]="filterService" [isOpen]="isOpen"
                    [fullScreen]="fullScreen"></composite-filter> -->
  <price-filter
    *ngSwitchCase="'priceChoice'"
    [filterService]="filterService"
    [isOpen]="isOpen"
    [fullScreen]="fullScreen"></price-filter>
</div>
