<div class="mobile-search">
  <!--Header-->
  <div class="tw-flex tw-items-center tw-justify-between tw-px-3">
    <button class="tw-text-gray-11" (click)="closeDialog()" type="button">
      <span class="fa" [ngClass]="{ 'fa-chevron-left': !!menuItem, 'fa-times': !menuItem }"></span>
    </button>
    <h4 class="twc-h3 tw-my-3">{{ title }}</h4>
    <div><!--empty div required --></div>
  </div>

  <!--Main menu-->
  <div *ngIf="!menuItem" class="mobile-filter-offset">
    <ul class="content-list items-list">
      <li
        class="content-list-item search-item"
        *ngFor="let menuItem of mainMenuItems"
        (click)="selectMenuItem(menuItem)"
        (keydown.enter)="selectMenuItem(menuItem)"
        tabindex="0">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              {{ menuItem.name }}
              <div class="filter-value" [innerText]="menuItem.selectionNote()"></div>
            </div>

            <div class="flex-col flex-col-2x text-right text-muted">
              <span class="fa fa-chevron-right"></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <!--Filters-->
  <div *ngIf="menuItem?.mode === 'filter'" class="mobile-filter-offset">
    <div *ngIf="!currentFilter">
      <div *ngFor="let filter of allFilters" class="">
        <div *ngIf="filter.visible">
          <ul
            class="content-list items-list"
            *ngFor="let subFilter of filter.subfilters || [filter]"
            tabindex="0"
            (keydown.enter)="selectFilter(subFilter)"
            (click)="selectFilter(subFilter)">
            <li class="content-list-item search-item" [ngClass]="{ 'has-selected-item': subFilter.selectedItems }">
              <div class="flex flex-vertical-center">
                <div class="flex-row">
                  <div class="flex-col flex-col-grow">
                    {{ subFilter.label }}
                    <div class="filter-value">
                      <span *ngIf="subFilter.value">{{ subFilter.value }}</span>
                      <span *ngIf="subFilter.selectedItems.length <= 5 && subFilter.mode !== 'priceChoice'">
                        <span *ngFor="let item of subFilter.selectedItems; let last = last">
                          {{ item.text }}
                          <span *ngIf="subFilter.mode === 'datesChoice'">: {{ formatDate(item.value) }}</span>
                          <selected-filter-rating-dep
                            [item]="item"
                            *ngIf="subFilter.name === 'CourseRatingFilter'"></selected-filter-rating-dep>
                          <span *ngIf="!last">,&nbsp;</span>
                        </span>
                        <!--Custom values for price filter CoursePriceFilter-->
                        <span *ngIf="subFilter.mode === 'priceChoice' && filter.visible">
                          <span *ngIf="isNumber(filter.items[0].value) || isNumber(filter.items[1].value)">
                            <span *ngIf="isNumber(filter.items[0].value)">
                              {{ filter.items[0].text }} : ${{ filter.items[0].value }}</span
                            >
                            <span *ngIf="isNumber(filter.items[1].value)">
                              {{ filter.items[1].text }} : ${{ filter.items[1].value }}</span
                            >
                          </span>
                          <span
                            *ngIf="
                              filter.items[2].value &&
                              (isNumber(filter.items[0].value) || isNumber(filter.items[1].value))
                            "
                            >,</span
                          >
                          <span *ngIf="filter.items[2].value">Free</span>
                        </span>
                      </span>
                      <span *ngIf="subFilter.selectedItems.length > 5">
                        {{ subFilter.selectedItems.length }} filters selected
                      </span>
                    </div>
                  </div>

                  <div class="flex-col flex-col-2x text-right text-muted">
                    <span class="fa fa-chevron-right"></span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="currentFilter" class="mobile-search-filter">
      <search-filter-view-dep [filter]="currentFilter" [fullScreen]="true"></search-filter-view-dep>
    </div>
  </div>

  <!--Sorting-->
  <div *ngIf="menuItem?.mode === 'sort'" class="mobile-filter-offset">
    <ul class="content-list">
      <li
        class="content-list-item search-item"
        [ngClass]="{ 'search-item-selected': term.value === sort }"
        *ngFor="let term of sortTerms"
        (click)="selectSortTerm(term)"
        (keydown.enter)="selectSortTerm(term)"
        tabindex="0">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              {{ term.name }}
            </div>

            <div class="flex-col flex-col-2x text-right text-muted">
              <span class="fa fa fa-check" *ngIf="term.value === sort"></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div class="mobile-search-footer">
    <button type="button" class="btn btn-default pull-left" *ngIf="menuItem?.mode !== 'sort'" (click)="resetFilters()">
      Reset {{ currentFilter ? 'Filter' : 'All' }}
    </button>
    <button
      type="button"
      class="btn btn-primary pull-right"
      [ngClass]="{ 'full-width': menuItem?.mode === 'sort' }"
      (click)="showResults()">
      Show Results
    </button>
  </div>
</div>
