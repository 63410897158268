<div *ngIf="filter.visible">
  <div ngbAccordion *ngIf="!fullScreen" class="truncate-labels">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button type="button" ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{ filter.label }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="flex" *ngIf="selectedItems.length > 0">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <a href="#" title="" (click)="openFilter()">
                    <span *ngIf="selectedItems.length > 1">{{ selectedItems.length }} items selected</span>
                    <span *ngIf="selectedItems.length < 2">{{ selectedItems.length }} item selected</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-row">
                <div class="flex-col flex-col-grow">
                  <button
                    type="button"
                    class="btn btn-success btn-block"
                    *ngIf="filter.selectedItems.length <= 0"
                    (click)="openFilter()">
                    Select {{ filter.label }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger btn-block"
                    *ngIf="filter.selectedItems.length > 0"
                    (click)="clearSelection()">
                    Remove {{ filter.label }}
                  </button>

                  <div *ngIf="selectedItems.length > 0">
                    <div class="checkbox" *ngFor="let item of termItems">
                      <label class="use-hand-cursor no-margin-top no-margin-bottom">
                        <input
                          type="checkbox"
                          [checked]="item.inverted ? !item.selected : item.selected"
                          (change)="item.selected = !item.selected; applyFilter(item.value, item.term)" />
                        <span [innerHtml]="item.text"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen" class="">
    <searchable-multi-choice-content [filterService]="filterService"></searchable-multi-choice-content>
  </div>
</div>
