import { Injectable } from '@angular/core';
import { IUploadedTmpFile } from 'components/file-upload/single-file-upload.upgraded';

export interface ITMPFiles {
  [key: string]: IUploadedTmpFile;
}

@Injectable()
export class QuizTemporaryFilesService {
  temporaryFiles: ITMPFiles = {};

  storeFile(fileObj: IUploadedTmpFile) {
    this.temporaryFiles[fileObj.name] = fileObj;
  }

  getFiles(): ITMPFiles {
    return this.temporaryFiles;
  }

  getStoredFile(name: string): IUploadedTmpFile | null {
    return this.temporaryFiles[name] || null;
  }

  clearFiles() {
    this.temporaryFiles = {};
  }
}
