<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Your Saved Searches</h4>
</div>

<ul class="content-list">
  <li class="content-list-item" *ngFor="let item of savedSearches">
    <div class="flex sm-margin-left">
      <div class="flex-row" [hidden]="item.editing">
        <div class="flex-col flex-col-grow xs-margin-left">
          <button
            type="button"
            class="btn btn-link no-padding-all text-left"
            *ngIf="!draft"
            title="Saved search"
            [uiSref]="'main.search'"
            [uiParams]="item.params"
            [uiOptions]="{ inherit: false }"
            (click)="close()">
            {{ item.name }}
          </button>
          <span *ngIf="!!draft" class="use-not-allowed-cursor text-muted underline">{{ item.name }}</span>
        </div>
        <div class="flex-col text-right" style="min-width: 80px">
          <button
            type="button"
            class="btn btn-xs btn-link text-muted font-18px"
            aria-label="Edit"
            [disabled]="!!draft"
            [hidden]="item.pending"
            title="Edit"
            (click)="editItem(item)">
            <span class="fa fa-edit"></span>
          </button>
          <button
            type="button"
            class="btn btn-xs btn-link text-muted font-18px"
            aria-label="Remove"
            data-spinner-color="#000"
            [disabled]="!!draft || item.pending"
            [ladda]="item.pending"
            title="Remove"
            (click)="removeItem(item)">
            <span class="fa fa-trash"></span>
          </button>
        </div>
      </div>
      <ng-container *ngIf="item.editing && !!draft">
        <form #saveSearchForm="ngForm" (ngSubmit)="submit(item)" name="saveSearchForm">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <input
                type="text"
                class="form-control required"
                name="searchTitle"
                placeholder="Please enter a name for your new saved search"
                maxlength="500"
                required
                noWhiteSpace
                autoFocus
                [(ngModel)]="draft.name" />
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <label>
                <input type="checkbox" name="notify" [(ngModel)]="draft.notify" /> Email me if new courses are added
                that match this saved search
              </label>
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <button
                type="submit"
                class="btn btn-success btn-sm pull-right"
                data-spinner-color="#000"
                [ladda]="processing"
                [disabled]="saveSearchForm.invalid">
                <span class="fa fa-check hidden-xs"></span> OK
              </button>
              <button
                type="button"
                class="btn btn-warning btn-sm pull-right"
                [disabled]="processing"
                (click)="cancelEdit(item)">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </li>
</ul>
