import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import { IExternalApplicationKey } from 'modules/user/external-applications/models/external-application-key.model';
import { Observable } from 'rxjs';

enum ExternalApplicationKeysEndpoints {
  GET = '/a/users/:userId/external-applications/:applicationId/keys/:keyId/',
  QUERY = '/a/users/:userId/external-applications/:applicationId/keys/',
  CREATE = '/a/users/:userId/external-applications/:applicationId/keys/',
  DELETE = '/a/users/:userId/external-applications/:applicationId/keys/:keyId/',
}

@Injectable()
export class ExternalApplicationKeyService {
  constructor(private http: HttpClient) {}

  public get(applicationId: number, userId: number, keyId: number): Observable<IExternalApplicationKey> {
    return this.http.get<IExternalApplicationKey>(
      ElmsUtils.formatUrl(ExternalApplicationKeysEndpoints.GET, { applicationId, userId, keyId }),
    );
  }

  public query(applicationId: number, userId: number): Observable<IExternalApplicationKey[]> {
    return this.http.get<IExternalApplicationKey[]>(
      ElmsUtils.formatUrl(ExternalApplicationKeysEndpoints.QUERY, { applicationId, userId }),
    );
  }

  public generateKey(
    applicationId: number,
    userId: number,
    expirationDate: string,
  ): Observable<IExternalApplicationKey> {
    return this.http.post<IExternalApplicationKey>(
      ElmsUtils.formatUrl(ExternalApplicationKeysEndpoints.CREATE, { applicationId, userId }),
      { expirationDate },
    );
  }

  public delete(applicationId: number, userId: number, keyId: number, throwHttpErrors = false): Observable<void> {
    return this.http.delete<void>(
      ElmsUtils.formatUrl(ExternalApplicationKeysEndpoints.DELETE, { applicationId, userId, keyId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
    );
  }
}
