<div class="course-wizard-panel">
  <div class="d-flex space-between mb-0">
    <h3 class="no-margin-all">API v1</h3>
    <button
      type="button"
      (click)="openModal()"
      class="btn btn-default"
      title="Generate access token"
      name="generateAuthTokenBtn">
      Generate Access Key
    </button>
  </div>

  <div class="course-details-settings mt-10" *ngIf="accessToken">
    <div class="row standard-margin-bottom">
      <div class="col-sm-2">
        <strong>API Access Key:</strong>
      </div>
      <div class="col-sm-10">
        <span>{{ accessToken }}</span>
        <button
          type="button"
          (click)="copyAccessToken()"
          title="Copy api key to clipboard"
          class="inline-block sm-margin-left">
          <span class="fa-regular fa-copy" aria-hidden="true" style="font-size: 1.2em; cursor: pointer"></span>
          <span class="sr-only">Copy</span>
        </button>
      </div>
    </div>

    <fieldset name="examplesFields">
      <legend>Examples</legend>

      <div class="row mb-10">
        <div class="col-sm-2">
          <span class="font-bold">Header:</span>
        </div>
        <div class="col-sm-10">
          "Authorization: Token <span>{{ accessToken }}</span
          >"
        </div>
      </div>
      <div class="row sm-margin-bottom">
        <div class="col-sm-2">
          <span class="font-bold">Curl:</span>
        </div>
        <div class="col-sm-10">
          curl -X GET "{{ request_url }}" -H "accept: application/json" -H "Authorization: Token
          <span>{{ accessToken }}</span
          >"
        </div>
      </div>
    </fieldset>
  </div>
</div>
