import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { IUrlParams, UrlParamHandler } from 'core/navigation/models/navigation-url.model';
import _ from 'lodash';
import {
  ISearchFilter,
  ISearchFilterChanges,
  ISearchFilterItem,
  ISearchFilterService,
} from 'modules/search/models/search-filters.models';
import { Unsubscribable } from 'rxjs';

@Component({
  standalone: false,
  selector: 'selected-search-filters',
  templateUrl: './selected-search-filters.component.html',
})
export class SelectedSearchFiltersComponent implements OnInit, OnDestroy {
  @Input() filterService: ISearchFilterService;
  @Input() isOpen?: boolean;
  @Input() fullScreen?: boolean;
  @Output() filterChanged = new EventEmitter<ISearchFilterChanges>();

  limitItems = 5;
  filter: ISearchFilter;

  private urlHandlerDestroy: UrlParamHandler;
  private urlParams: IUrlParams;
  private subscriber: Unsubscribable;

  constructor(private urlService: UrlService) {}

  ngOnInit(): void {
    this.filter = this.filterService.filter;

    this.ngOnUrlChange();
    this.urlHandlerDestroy = <UrlParamHandler>this.urlService.onChange(() => this.ngOnUrlChange());
    this.subscriber = this.filterService.filterChanged().subscribe((changes) => this.filterChanged.emit(changes));
  }

  ngOnDestroy(): void {
    this.urlHandlerDestroy();
    this.subscriber?.unsubscribe();
    this.subscriber = null;
  }

  ngOnUrlChange() {
    const params = _.pickBy(this.urlService.search(), _.identity);

    if (!_.isEqual(params, this.urlParams)) {
      this.urlParams = params;
      this.filterService.extractConditions();
    }
  }

  applyFilter(value: string, term?: string): boolean {
    return this.filterService.applyFilter(value, term);
  }

  getText(item: ISearchFilterItem): string {
    return this.filterService.representItem(item);
  }

  getTitle(): string {
    const items = [];
    const selectedItems = this.filter.selectedItems.slice(0, this.limitItems);

    _.forEach(selectedItems, (item) => {
      items.push(this.getText(item));
    });

    if (selectedItems.length < this.filter.selectedItems.length) {
      items.push('and ' + (this.filter.selectedItems.length - this.limitItems) + ' more');
    }

    return items.join(',\n');
  }

  isNumber(item: string | number): boolean {
    return _.isNumber(item);
  }

  clearSelection() {
    this.filterService.clear();
  }

  combineTextFormatter(): string {
    return this.filterService.represent();
  }
}
