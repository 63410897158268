<div *ngIf="!error">
  <div *ngIf="player">
    <!--Step: quiz start-->
    <div *ngIf="player.playerCurrentStep === 'start'" class="">
      <div class="quiz-introduction standard-margin-top">
        <p *ngIf="quizResult.quiz.introductoryText" [innerHTML]="quizResult.quiz.introductoryText | markdownToHtml"></p>

        <p *ngIf="quizResult.quiz.anonymousResults" class="alert alert-warning">The results will be anonymous.</p>

        <p *ngIf="quizResult.quiz.attemptsAllowed !== 1 && quizResult.quiz.scoreTypeId">
          Attempts Allowed:
          <span *ngIf="quizResult.quiz.attemptsAllowed > 1">{{ quizResult.quiz.attemptsAllowed }}</span>
          <span class="text-italic" *ngIf="!quizResult.quiz.attemptsAllowed">unlimited</span>
          <span *ngIf="quizResult.attempt">Attempts Used: {{ quizResult.attempt - 1 }}.</span>
        </p>

        <p *ngIf="warningText" class="alert alert-warning">{{ warningText }}</p>
        <p *ngIf="quizResult.quiz.timeLimit > 0" class="alert alert-info">
          Time allowed for the test:
          <strong>
            <span [ngPlural]="limitHours" *ngIf="limitHours > 0">
              <ng-template ngPluralCase="one">1 hour</ng-template>
              <ng-template ngPluralCase="other">{{ limitHours }} hours</ng-template>
            </span>
            <span *ngIf="limitMinutes > 0" [ngPlural]="limitMinutes">
              <ng-template ngPluralCase="one">1 minute</ng-template>
              <ng-template ngPluralCase="other">{{ limitMinutes }} minutes</ng-template>
            </span> </strong
          >. Clicking 'Start' will start the test and the time countdown.
        </p>
        <p
          *ngIf="!quizResult.quiz.introductoryText && introText && !quizResult.quiz.timeLimit"
          class="alert alert-info">
          {{ introText }}
        </p>
        <p
          *ngIf="!quizResult.quiz.introductoryText && !introText && !quizResult.quiz.timeLimit"
          class="alert alert-info">
          Click the 'Start button' when you are ready to take the quiz.
        </p>
      </div>
      <div class="text-center">
        <button
          type="button"
          style="min-width: 120px"
          [disabled]="!browserIsOnline || player.started"
          class="btn btn-lg btn-success xs-margin-bottom-phone"
          (click)="player.start()">
          {{ quizResultService.isCompleted(quizResult) || !player.ownQuiz ? 'Review' : 'Start' }}
        </button>
        <ng-content select="[startButtons]"></ng-content>
      </div>
    </div>

    <div *ngIf="player.playerCurrentStep === 'continue'" class="">
      <div class="quiz-introduction standard-margin-top">
        <p *ngIf="quizResult.quiz.introductoryText" [innerHtml]="quizResult.quiz.introductoryText | markdownToHtml"></p>
        <p *ngIf="warningText" class="alert alert-warning">{{ warningText }}</p>
        <p *ngIf="quizResult.quiz.timeLimit > 0" class="alert alert-info">
          Time allowed for the test:
          <strong>
            <span [ngPlural]="limitHours" *ngIf="limitHours > 0">
              <ng-template ngPluralCase="one">1 hour</ng-template>
              <ng-template ngPluralCase="other">{{ limitHours }} hours</ng-template>
            </span>
            <span *ngIf="limitMinutes > 0" [ngPlural]="limitMinutes">
              <ng-template ngPluralCase="one">1 minute</ng-template>
              <ng-template ngPluralCase="other">{{ limitMinutes }} minutes</ng-template>
            </span> </strong
          >. Time remaining:
          <strong>
            <span *ngIf="timeLeftHours > 0" [ngPlural]="timeLeftHours">
              <ng-template ngPluralCase="one">1 hour</ng-template>
              <ng-template ngPluralCase="other">{{ timeLeftHours }} hours</ng-template>
            </span>
            <span *ngIf="timeLeftMinutes > 0" [ngPlural]="timeLeftMinutes">
              <ng-template ngPluralCase="one">1 minute</ng-template>
              <ng-template ngPluralCase="other">{{ timeLeftMinutes }} minutes</ng-template>
            </span>
            <span *ngIf="timeLeftSeconds > 0" [ngPlural]="timeLeftSeconds">
              <ng-template ngPluralCase="one">1 second</ng-template>
              <ng-template ngPluralCase="other">{{ timeLeftSeconds }} seconds</ng-template>
            </span> </strong
          >. Clicking 'Continue' will continue where you've left off.
        </p>
        <p *ngIf="!quizResult.quiz.timeLimit">Clicking 'Continue' will continue where you've left off.</p>
      </div>
      <div class="text-center">
        <button
          type="button"
          style="min-width: 120px"
          [disabled]="!browserIsOnline || player.started"
          title="Continue"
          class="btn btn-lg btn-success xs-margin-bottom-phone"
          (click)="player.start()">
          Continue
        </button>
        <ng-content select="[startButtons]"></ng-content>
      </div>
    </div>

    <!--Step: Questions-->
    <div *ngIf="player.playerCurrentStep === 'questions' && player.currentQuestion" class="">
      <div class="panel panel-default standard-margin-top sm-margin-bottom">
        <div
          class="panel-heading"
          [ngClass]="{
            'panel-success bg-success': showQuestionCorrectness() && player.currentQuestion.correct,
            'panel-danger bg-danger': showQuestionCorrectness() && !player.currentQuestion.correct,
          }">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <h4
                [ngClass]="{
                  'text-success': showQuestionCorrectness() && player.currentQuestion.correct,
                  'text-danger': showQuestionCorrectness() && !player.currentQuestion.correct,
                }">
                Question&nbsp;<span>{{ player.currentQuestionIndex + 1 }}</span
                >&nbsp;of&nbsp;<span> {{ quizResult.questions.length }}</span>

                <span *ngIf="showQuestionCorrectness()">
                  <span>&bull;</span>
                  <span *ngIf="player.currentQuestion.correct">Correct</span>
                  <span *ngIf="!player.currentQuestion.correct">Incorrect</span>
                </span>
              </h4>
            </div>
            <div
              class="col-xs-12 col-sm-6 text-right text-left-xs"
              *ngIf="player.endTime && quizResult.quiz.timeLimit > 0 && duration">
              <h4 class="no-margin-top-phone xs-margin-bottom-phone">
                Time left <span *ngIf="duration.hours() > 0">{{ duration.hours() }}h</span>
                <span *ngIf="duration.hours() > 0 || duration.minutes() > 0"> {{ duration.minutes() }}m</span>
                <span> {{ duration.seconds() }}s</span>
              </h4>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <span id="progressbar" class="sr-only">Questions progress</span>
          <div class="progress" *ngIf="quizResult.questions.length > 1">
            <ngb-progressbar
              [value]="player.questionsProgress"
              [max]="100"
              [striped]="true"
              [animated]="true"
              type="success"
              class="progress-striped active w-100">
            </ngb-progressbar>
          </div>
          <fieldset class="display-block" [disabled]="loading.length">
            <quiz-player-question
              [question]="player.currentQuestion"
              [scoreTypeId]="scoreTypeId"
              [quizResult]="quizResult"></quiz-player-question>
            <div class="row">
              <div class="col-xs-12">
                <button
                  type="button"
                  class="btn btn-danger pull-left"
                  (click)="player.previousQuestion()"
                  [disabled]="!browserIsOnline"
                  *ngIf="player.currentQuestionIndex > 0"
                  title="Back">
                  Back
                </button>
                <button
                  type="button"
                  title="Next Question"
                  [ladda]="loading === 'nextQuestion'"
                  data-spinner-color="#000"
                  [disabled]="
                    !browserIsOnline ||
                    (!quizResultService.questionHasAnswer(player.currentQuestion) &&
                      !quizResultService.isCompleted(player.result))
                  "
                  class="btn btn-success pull-right"
                  (click)="nextQuestion()">
                  Next
                </button>
                <ng-content select="[launchButtons]"></ng-content>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <!--Step: quiz presubmit-->
    <div *ngIf="player.playerCurrentStep === 'pendingCompletion'" class="">
      <div class="quiz-introduction standard-margin-top">
        <p class="alert alert-info">
          <span
            >If you want to submit your test for grading, click <strong>Submit For Grading</strong> button or click
            <strong>Return to Test</strong> to review your test.</span
          >
          <span *ngIf="player.endTime && quizResult.quiz.timeLimit > 0 && duration" class="pull-right">
            Time left <span *ngIf="duration.hours() > 0">{{ duration.hours() }}h</span>
            <span *ngIf="duration.hours() > 0 || duration.minutes() > 0"> {{ duration.minutes() }}m</span>
            <span> {{ duration.seconds() }}s</span>
          </span>
        </p>
      </div>
      <div class="text-center">
        <div>
          <button
            type="button"
            [disabled]="!browserIsOnline"
            class="btn btn-danger pull-left"
            title="Return"
            (click)="player.playerCurrentStep = 'questions'">
            Return to Test
          </button>
          <button
            type="button"
            [disabled]="!browserIsOnline"
            data-spinner-color="#000"
            class="btn btn-success pull-right"
            (click)="player.finish()"
            title="Submit">
            Submit For Grading
          </button>
        </div>
      </div>
    </div>

    <!--Step: time ends-->
    <div *ngIf="player.playerCurrentStep === 'timeEnds'">
      <div class="quiz-conclusion">
        <div class="panel panel-default standard-margin-top">
          <div class="panel-body">
            <div class="text-center alert alert-warning">
              <ng-content select="[timeEndsText]"></ng-content>
            </div>
            <div class="text-center sm-margin-top">
              <button
                class="btn btn-default btn-lg"
                (click)="player.finish()"
                [disabled]="!browserIsOnline"
                type="button"
                title="Next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Step: days to complete lock-->
    <div *ngIf="player.playerCurrentStep === 'finishAttempt'">
      <div class="quiz-conclusion">
        <div class="panel panel-default standard-margin-top">
          <div class="panel-body">
            <div class="text-center alert alert-warning">
              You can not proceed this attempt. The result will be calculated based on the answers you have entered
              until this point.
            </div>
            <div class="text-center sm-margin-top">
              <button class="btn btn-default btn-lg" (click)="player.finish()" type="button" title="Submit Result">
                Submit Result
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Step: finish quiz-->
    <div
      *ngIf="
        quizResult.statusId === 4 &&
        quizResult.remainingDays &&
        player.playerCurrentStep === 'finish' &&
        (quizResult.attempt < quizResult.quiz.attemptsAllowed || !quizResult.quiz.attemptsAllowed)
      "
      class="remaining-info">
      <span [ngPlural]="quizResult.remainingDays"
        >You have <b>{{ quizResult.remainingDays }}</b>
        <ng-template ngPluralCase="one">day</ng-template>
        <ng-template ngPluralCase="other">days</ng-template> remaining to pass this Assessment</span
      >
    </div>
    <div *ngIf="player.playerCurrentStep === 'finish'">
      <div *ngIf="quizResultService.isCompleted(quizResult)" class="quiz-conclusion">
        <div
          class="panel standard-margin-top"
          [ngClass]="{
            'panel-default': quizResult.statusId === 1,
            'panel-success': quizResult.statusId === 2 || quizResult.statusId === 3,
            'panel-warning': quizResult.statusId === 5,
            'panel-danger': quizResult.statusId === 4,
          }">
          <div
            class="panel-heading text-center"
            *ngIf="(quizResult.quiz.scoreTypeId || scoreTypeId) && quizResult.score !== null">
            <div class="lead no-margin-bottom">
              <span>{{ quizResult.statusName }}</span>
            </div>
          </div>

          <div class="panel-body">
            <div *ngIf="!quizResult.quiz.finalText">
              <div #finishText class="text-center">
                <ng-content select="[finishText]"></ng-content>
              </div>
              <div *ngIf="!finishText.children.length" class="text-center">Thank you for taking the quiz.</div>
            </div>
            <div *ngIf="quizResult.quiz.finalText">
              <div [innerHtml]="quizResult.quiz.finalText | markdownToHtml"></div>
            </div>

            <div
              class="f-row f-row-centered sm-margin-top text-center"
              *ngIf="
                quizResult.quiz.resultTypeId === 1 &&
                (quizResult.quiz.scoreTypeId || scoreTypeId) &&
                quizResult.score !== null
              ">
              <div
                class="f-col-xs-8 f-col-sm-3 standard-margin-right standard-margin-left sm-margin-bottom well well-sm">
                <div>
                  <div>
                    <strong>Your Score</strong>
                    <div
                      [ngClass]="{
                        'text-success': quizResult.statusId === 2 || quizResult.statusId === 3,
                        'text-danger': quizResult.statusId === 4,
                      }">
                      <h1 class="sm-margin-top">
                        <span class="sr-only">Your score is: </span>
                        <span *ngIf="quizResult.passingScore.scoreType === 2 || scoreTypeId === 2">{{
                          formatDigit(quizResult.score)
                        }}</span>
                        <span *ngIf="quizResult.passingScore.scoreType === 1 || scoreTypeId === 1">{{
                          formatDigit(quizResult.scorePercentage)
                        }}</span
                        ><span
                          *ngIf="
                            quizResult.quiz.scoreTypeId === 1 ||
                            quizResult.passingScore.scoreType === 1 ||
                            scoreTypeId === 1
                          "
                          >%</span
                        >
                        <span
                          *ngIf="
                            (quizResult.quiz.scoreTypeId === 2 && quizResult.passingScore.scoreType !== 1) ||
                            scoreTypeId === 2
                          "
                          >&nbsp;points</span
                        >
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="f-col-xs-8 f-col-sm-3 standard-margin-right standard-margin-left sm-margin-bottom well well-sm"
                *ngIf="quizResult.passingScore">
                <div>
                  <div>
                    <strong>Passing Score</strong>
                    <div>
                      <h1 class="sm-margin-top">
                        {{ quizResult.passingScore.score | number: '1.0-0'
                        }}<span *ngIf="quizResult.passingScore.scoreType === 1">%</span
                        ><span *ngIf="quizResult.passingScore.scoreType === 2">&nbsp;points</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="text-center sm-margin-top"
              *ngIf="
                (quizResult.quiz.scoreTypeId || scoreTypeId) &&
                quizResultService.isCompleted(quizResult) &&
                (quizResult.quiz.resultsReviewAllowed ||
                  (quizResult.statusId === 4 &&
                    (quizResult.quiz.attemptsAllowed > 1 || !quizResult.quiz.attemptsAllowed)))
              ">
              <button
                *ngIf="quizResult.quiz.resultsReviewAllowed"
                [disabled]="!browserIsOnline"
                class="btn btn-default btn-lg xs-margin-bottom-phone xs-margin-right"
                (click)="player.start()"
                type="button"
                title="Review">
                Review
              </button>
              <span
                *ngIf="
                  quizResult.statusId === 4 &&
                  (quizResult.attempt < quizResult.quiz.attemptsAllowed || !quizResult.quiz.attemptsAllowed)
                ">
                <ng-content select="[retakeButtons]"></ng-content>
              </span>
              <span *ngIf="quizResult.statusId === 4">
                <ng-content select="[finishButtons]"></ng-content>
              </span>
            </div>

            <div
              class="text-center sm-margin-top"
              *ngIf="!quizResult.quiz.scoreTypeId && !scoreTypeId && quizResultService.isCompleted(quizResult)">
              <ng-content select="[finishButtons]"></ng-content>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!quizResultService.isCompleted(quizResult)">
        <div class="load-spinner"></div>
        <div class="text-center">Finishing...</div>
      </div>
    </div>
    <div *ngIf="player.playerCurrentStep === 'skipped'">
      <ng-content select="[skipText]"></ng-content>
    </div>
  </div>
  <div *ngIf="!player">
    <div class="load-spinner"></div>
  </div>
</div>
<div class="row" *ngIf="error">
  <div class="content">
    <div class="alert alert-sm alert-danger standard-margin-top">{{ error }}</div>
  </div>
</div>
