import { Component, Input } from '@angular/core';
import { ISearchFilterItem } from 'modules/search/models/search-filters.models';

@Component({
  standalone: false,
  selector: 'selected-filter-rating',
  templateUrl: './selected-filter-rating.component.html',
})
export class SelectedFilterRatingComponent {
  @Input() item: ISearchFilterItem;

  get range(): number[] {
    return [1, 2, 3, 4, 5];
  }

  toInt(value: string | number): number {
    return typeof value === 'string' ? parseInt(value) : value;
  }
}
