import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { searchRoutingModule } from 'modules/search/search-routing.module';
import { SearchComponent } from './components/search.component';
import { SearchService } from './services';
import { SavedSearchesServive } from './services/saved-searches.service';

@NgModule({
  imports: [UIRouterModule, SearchComponent, UIRouterUpgradeModule.forChild({ states: searchRoutingModule })],
  providers: [SearchService, SavedSearchesServive],
})
export class SearchModule {}
