<div class="row sm-margin-bottom" *ngIf="!selfView">
  <div class="col-sm-6 col-xs-12">
    <button
      type="button"
      class="btn btn-link modern-back-button"
      [trackLinkClick]="trackingName + '_back'"
      title="Go back"
      (click)="goBack()">
      <span aria-hidden="true" class="fa fa-chevron-left"></span>Back
    </button>
  </div>
</div>

<!--Search panel-->
<div *ngIf="!!user?.userNetwork?.profile">
  <fieldset aria-label="NetworkSearch" [disabled]="!!searchRequestSubscriber" class="well well-sm sm-padding-all">
    <div class="row">
      <!--Search Input-->
      <div
        class="col-xs-12 sm-margin-bottom-phone"
        [ngClass]="{
          'col-sm-6': selfView && user?.userNetwork?.profile,
          'col-sm-9': !selfView || !user?.userNetwork?.profile,
        }">
        <keyword-search-form
          [(value)]="query"
          class="no-margin-bottom"
          (submit)="search()"
          [pending]="!!searchRequestSubscriber"
          placeholder="Search"></keyword-search-form>
      </div>

      <!--Filters ddl-->
      <div class="col-sm-3 col-xs-12 sm-margin-bottom-phone">
        <select
          aria-label="Search Filters"
          class="form-control"
          [(ngModel)]="filterType"
          name="filters"
          [trackLinkClick]="trackingName + '_search_filters'"
          (ngModelChange)="search()">
          <option [ngValue]="f.value" *ngFor="let f of activityFilters">{{ f.name }}</option>
        </select>
      </div>

      <div class="col-sm-3 col-xs-12" *ngIf="selfView && user.userNetwork?.profile">
        <button
          type="button"
          title="Start thread"
          class="btn btn-default btn-block"
          (click)="prepareNewPost()"
          [trackLinkClick]="trackingName + '_new_post'">
          Start thread
        </button>
      </div>
    </div>
  </fieldset>
</div>

<div class="row visible-xs visible-sm mb-20">
  <div class="col-xs-12">
    <user-profile-short-info [user]="user" [trackingName]="trackingName"></user-profile-short-info>
  </div>
</div>

<div class="row">
  <!--Activities-->
  <div class="col-sm-12 col-md-8 col-xs-12 standard-margin-bottom-xs no-padding-right-md">
    <div *ngIf="!!searchRequestSubscriber && !activities.length && user.userNetwork?.profile">
      <div class="load-spinner"></div>
    </div>

    <!--Activities List-->
    <div *ngIf="user.userNetwork?.profile" class="d-flex flex-direction-column gap-20">
      <network-activity
        *ngFor="let activity of activities"
        [activityOrigin]="activityOrigin"
        [activity]="activity"
        [allowLikes]="true"
        [showAvatar]="true"
        [query]="query"
        [systemMessagesEnable]="true"
        [trackingName]="trackingName">
      </network-activity>

      <div class="row" *ngIf="itemCount">
        <div class="col-xs-12">
          <div class="text-center sm-margin-top">
            <ngb-pagination
              *ngIf="itemCount > itemsPerPage"
              [collectionSize]="itemCount"
              [maxSize]="itemsPerPage"
              [rotate]="true"
              [ellipses]="false"
              (pageChange)="pageChanged($event)"
              [boundaryLinks]="true"
              [disabled]="!!searchRequestSubscriber"
              [page]="page"
              [pageSize]="itemsPerPage"
              size="sm">
              <ng-template ngbPaginationFirst>&laquo;</ng-template>
              <ng-template ngbPaginationLast>&raquo;</ng-template>
              <ng-template ngbPaginationPrevious>&lsaquo;</ng-template>
              <ng-template ngbPaginationNext>&rsaquo;</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center" *ngIf="!selfView && !user.userNetwork.profile.sharingEnabled">
      <span>This person is not sharing their profile.</span>
    </div>
  </div>

  <!--User Profile panel-->
  <div class="col-sm-12 col-md-4 col-xs-12">
    <user-profile-short-info
      [user]="user"
      [trackingName]="trackingName"
      class="hidden-xs hidden-sm mb-20 d-block"></user-profile-short-info>

    <div *ngIf="selfView && user.userNetwork.profile" class="d-flex flex-direction-column gap-20">
      <div class="primary-panel" *ngIf="!profileCompleted">
        <h4 class="mt-0 mb-10">Profile Strength</h4>
        <network-profile-completeness [trackingName]="trackingName" [user]="user"></network-profile-completeness>
      </div>

      <network-subscriptions
        [userId]="user.id"
        [activityOrigin]="activityOrigin"
        [count]="3"
        [trackingName]="trackingName"
        [mode]="'followed'"
        [narrowView]="true"></network-subscriptions>

      <network-subscriptions
        [userId]="user.id"
        [activityOrigin]="activityOrigin"
        [mode]="'suggested'"
        [trackingName]="trackingName"
        [narrowView]="true"></network-subscriptions>
      <div>
        <a
          class="btn btn-default btn-block"
          uiSref="main.search"
          [uiParams]="{ type: 'user' }"
          title="Find more users to follow"
          [trackLinkClick]="trackingName + '_find_more_users'"
          >Find more users to follow</a
        >
      </div>
    </div>
  </div>
</div>
