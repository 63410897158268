import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import { Observable, Subject, map, tap } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';
import { IUser, IUserDataResponse } from '../models/user.model';

enum UserServiceEndpoints {
  GET = '/a/user/:userId/',
  POST = '/a/user/:userId/',
  CHECK_NEW_EMAIL = '/a/user/:userId/check_new_email/',
}

@Injectable()
export class UserService {
  userChanged = new Subject<IUser>();

  constructor(
    private http: HttpClient,
    private globalConfig: GlobalConfig,
  ) {}

  get(userId?: number, permissions?: string[]): Observable<IUser> {
    let params = new HttpParams();

    if (permissions) {
      params = params.set('permitted_for', permissions.join(','));
    }

    return this.http
      .get<IUserDataResponse>(ElmsUtils.formatUrl(UserServiceEndpoints.GET, { userId }), { params })
      .pipe(map((data) => this.transform(data)));
  }

  checkEmail(user: IUser, newEmail: string, userActivated: boolean, throwHttpErrors = false): Observable<boolean> {
    const parameters = {
      email: newEmail || user.email,
      activeOnly: _.get(this.globalConfig, 'settings.user.checkDuplicatesForActiveOnly', null),
      userActivated: userActivated,
    };

    return this.http.post<boolean>(
      ElmsUtils.formatUrl(UserServiceEndpoints.CHECK_NEW_EMAIL, { userId: user.id }),
      parameters,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
    );
  }

  update(user: Partial<IUser>, throwHttpErrors?: boolean): Observable<IUser> {
    return this.http
      .post<IUserDataResponse>(
        ElmsUtils.formatUrl(UserServiceEndpoints.POST, { userId: user.id }),
        {
          ...user,
          groups: user.groups?.map((group) => group.id),
          subjectAreas: user.subjectAreas?.map((area) => area.id),
        },
        { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) },
      )
      .pipe(
        tap((ud) => this.userChanged.next(ud.user)),
        map((ud) => this.transform(ud)),
      );
  }

  isUserEditable(viewedUser: IUser): boolean {
    return this.checkPermission(viewedUser, 'user.edit') || this.checkPermission(viewedUser, 'user.super_edit');
  }

  checkPermission(user: IUser, permission: string): boolean {
    // permissions by user group roles
    return !!_.find(user.permissions, function (p) {
      return p.toLowerCase() === permission.toLowerCase();
    });
  }

  private transform(data: IUserDataResponse): IUser {
    const user = data.user;

    return {
      ...user,
      permissions: data.permissions,
      globalId: uuidV4(),
      files: {},
    };
  }
}
