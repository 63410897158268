import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SubjectAreasComponent } from './components/subject-areas.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [SubjectAreasComponent],
  exports: [SubjectAreasComponent],
  providers: [],
})
export class SubjectAreasModule {}
