<div *ngIf="filter.visible && filter.items.length">
  <div ngbAccordion *ngIf="!fullScreen && filter.appearance === 'panel'">
    <div ngbAccordionItem [collapsed]="!isOpen">
      <div ngbAccordionHeader>
        <button type="button" ngbAccordionButton>
          <span class="pull-right fa fa-fw fa-caret-down"></span>
          <span>{{ filter.label }}</span>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div *ngIf="!filter.popupOptions || filter.items.length < 10">
              <div *ngIf="!filter.selectedItems?.length">
                <div
                  class="content-list-item search-item search-input"
                  *ngIf="filter.items.length > (filter.displayLimit || 10)">
                  <input
                    type="search"
                    [(ngModel)]="searchQuery"
                    class="form-control"
                    id="filterSearch"
                    placeholder="Filter items" />
                  <label for="filterSearch" class="sr-only">Filter</label>
                </div>

                <div *ngFor="let item of filteredFilterItems" class="filter-item">
                  <a *ngIf="item.value" href="#" (click)="applyFilter(item.value)"
                    ><span [innerHtml]="item.text"></span>
                    <selected-filter-rating
                      [item]="item"
                      *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
                  </a>
                  <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{ inherit: false }"
                    ><span [innerHtml]="item.text"></span>
                    <selected-filter-rating
                      [item]="item"
                      *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
                  </a>
                </div>

                <!-- Expand/Collapse -->
                <div *ngIf="!searchQuery">
                  <div *ngIf="filter.displayLimit && filter.items.length > displayLimit">
                    <hr class="no-margin-top no-margin-bottom" />
                    <button type="button" class="btn-link text-left" title="View more" (click)="moreItems()">
                      more...
                    </button>
                  </div>
                  <div *ngIf="filter.displayLimit && filter.displayLimit < displayLimit">
                    <hr class="no-margin-top no-margin-bottom" />
                    <button type="button" class="btn-link text-left" title="View less" (click)="lessItems()">
                      less
                    </button>
                  </div>
                </div>
              </div>

              <div *ngIf="filter.selectedItems?.length" class="filter-item">
                <div>
                  <a href="#" (click)="clearSelection()"
                    ><span class="fa fa-chevron-left inline-block sm-margin-right"></span>Any
                    {{ filter.clearItemsLabel || filter.label }}</a
                  >
                </div>
                <div *ngFor="let item of filter.selectedItems">
                  <span [innerHtml]="item.text"></span>
                  <selected-filter-rating
                    [item]="item"
                    *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
                </div>
              </div>
            </div>

            <!-- Show popup filter when filter items more or equal than 10 and exists popup options -->
            <ul class="content-list" *ngIf="filter.popupOptions && filter.items.length >= 10">
              <li class="content-list-item" *ngIf="filter.selectedItems?.length">
                <div class="flex">
                  <div class="flex-row">
                    <div class="flex-col flex-col-grow">
                      <div *ngFor="let item of filter.selectedItems">
                        <a href="#" title="" (click)="openFilter()"><span [innerHtml]="item.text"></span></a>
                        <selected-filter-rating
                          [item]="item"
                          *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="content-list-item">
                <div class="flex">
                  <div class="flex-row">
                    <div class="flex-col flex-col-grow">
                      <button
                        type="button"
                        class="btn btn-success btn-block"
                        *ngIf="filter.selectedItems?.length === 0"
                        (click)="openFilter()">
                        Select Filter
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-block"
                        *ngIf="filter.selectedItems?.length > 0"
                        (click)="clearSelection()">
                        Remove Filter
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!fullScreen && filter.appearance === 'button'">
    <div
      class="btn-group btn-block"
      ngbDropdown
      #dropDown="ngbDropdown"
      [autoClose]="'outside'"
      (openChange)="isOpen = $event">
      <button id="SearchFilters" type="button" class="btn btn-default btn-block" ngbDropdownToggle>
        {{ filter.label }}
        <span class="fa fa-caret-down"></span>
      </button>

      <div ngbDropdownMenu>
        <div class="dropdown-filter-items">
          <div *ngIf="!selectedItems.length">
            <div
              class="content-list-item search-item search-input"
              *ngIf="filter.items.length > (filter.displayLimit || 10)">
              <input
                type="search"
                [(ngModel)]="searchQuery"
                (change)="searchQueryChanged()"
                class="form-control"
                id="filterSearchDropDown"
                placeholder="Filter items" />
              <label for="filterSearchDropDown" class="sr-only">Filter</label>
            </div>

            <div *ngFor="let item of filteredFilterItems" class="dropdown-filter-item">
              <a *ngIf="item.value" href="#" (click)="addItem($event, item)"
                ><span [innerHtml]="item.text"></span>
                <selected-filter-rating
                  [item]="item"
                  *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
              </a>
              <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{ inherit: false }"
                ><span [innerHtml]="item.text"></span>
                <selected-filter-rating
                  [item]="item"
                  *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
              </a>
            </div>
          </div>

          <div *ngIf="selectedItems.length" class="filter-item">
            <div class="dropdown-filter-item">
              <a href="#" (click)="clearSelection()"
                ><span class="fa fa-chevron-left inline-block sm-margin-right"></span>Any
                {{ filter.clearItemsLabel || filter.label }}</a
              >
            </div>
            <div *ngFor="let item of selectedItems" class="dropdown-filter-item">
              <span [innerHtml]="item.text"></span>
              <selected-filter-rating
                [item]="item"
                *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <div class="dropdown-filter-item" style="padding: 10px">
          <button
            type="button"
            [disabled]="!changed"
            class="btn btn-default btn-block"
            (click)="applySelection(); dropDown.close()">
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="fullScreen && !filter.popupOptions">
    <ul class="content-list">
      <li class="content-list-item search-item search-input">
        <input
          type="search"
          id="filterSearch0"
          [(ngModel)]="searchQuery"
          class="form-control"
          placeholder="Filter items" />
        <label for="filterSearch0" class="sr-only">Filter</label>
        <span class="fa fa-search"></span>
      </li>
    </ul>

    <ul
      class="content-list"
      *ngFor="let item of filteredFilterItems"
      (click)="applyFilter(item.value)"
      (keydown.enter)="applyFilter(item.value)"
      tabindex="0">
      <li class="content-list-item search-item" [ngClass]="{ 'search-item-selected': item.selected }">
        <div class="flex flex-vertical-center">
          <div class="flex-row">
            <div class="flex-col flex-col-grow">
              <span
                *ngIf="item.value"
                (click)="item.selected = !item.selected"
                (keydown.enter)="item.selected = !item.selected"
                tabindex="0">
                <span [innerHtml]="item.text"></span>
                <selected-filter-rating
                  [item]="item"
                  *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
              </span>

              <a *ngIf="item.link" uiSref="main.search" [uiParams]="item.link" [uiOptions]="{ inherit: false }"
                ><span [innerHtml]="item.text"></span>
                <selected-filter-rating
                  [item]="item"
                  *ngIf="filter.name === 'courseRatingFilter'"></selected-filter-rating>
              </a>
            </div>
            <div class="flex-col flex-col-2x text-right text-muted">
              <span class="fa fa fa-check" *ngIf="item.selected"></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="fullScreen && filter.popupOptions">
    <div [ngSwitch]="filter.popupOptions.component">
      <course-attribute-filter
        *ngSwitchCase="'courseAttributeFilter'"
        [filterService]="filterService"></course-attribute-filter>
      <competency-filter *ngSwitchCase="'competencyFilter'" [filterService]="filterService"></competency-filter>
      <session-label-filter *ngSwitchCase="'sessionLabelFilter'" [filterService]="filterService"></session-label-filter>
      <group-tags-search-filter
        *ngSwitchCase="'groupTagsSearchFilter'"
        [filterService]="filterService"></group-tags-search-filter>
    </div>
  </div>
</div>
