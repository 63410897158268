export const questionTypeExtension = {
  trueFalse: {
    id: 1,
    typeId: 1,
  },
  likertScale: {
    id: 2,
    typeId: 1,
  },
  yesNo: {
    id: 5,
    typeId: 1,
  },
};

export enum QuizQuestionType {
  multiChoice = 1,
  checkAllThatApply = 2,
  shortAnswer = 3,
  matching = 4,
  fillin = 5,
}

export enum QuizResultStatus {
  inProgress = 1,
  completed = 2,
  passed = 3,
  failed = 4,
  pending = 5,
}

export enum QuizType {
  preAssessment = 1,
  assessment = 2,
  evaluation = 3,
  exam = 4,
  survey = 5,
  practice = 6,
  preEvaluation = 7,
  followupEvaluation = 8,
}

export enum QuizTypeCategory {
  assessment = 2,
  evaluation = 3,
}

export enum QuizIntendedFor {
  learners = 1,
  administrators = 2,
}

export const quizEnums = {
  resultStatus: QuizResultStatus,
  quizTypes: QuizType,
  contentQuizTypes: [QuizType.exam, QuizType.survey],
  quizTypeCategories: QuizTypeCategory,
  quizIntendedFor: QuizIntendedFor,
};

export const quizQuestionEnums = {
  questionType: QuizQuestionType,
  questionTypeExtension: questionTypeExtension,
};
