import { NgModule } from '@angular/core';
import { EditUsersAvatarDirective } from './components/user-avatar-new-directive';

@NgModule({
  imports: [],
  declarations: [EditUsersAvatarDirective],
  exports: [EditUsersAvatarDirective],
  providers: [],
})
export class EditUserModule {}
