<div class="primary-panel">
  <div class="d-flex items-center overflow-hidden text-truncate">
    <div class="mr-15">
      <user-avatar [user]="user" [size]="52"></user-avatar>
    </div>
    <div class="w-100">
      <h4 class="mt-0 mb-5">{{ user.firstName }} {{ user.lastName }}</h4>
      <div *ngIf="selfView">
        <button
          type="button"
          class="btn btn-text btn-link"
          title="Edit profile"
          name="editButton"
          (click)="goEdit()"
          [id]="trackingName + '_edit_profile'">
          Edit profile
        </button>
      </div>
      <div *ngIf="!selfView" class="">
        <button
          type="button"
          class="btn btn-default"
          (click)="changeSubscription()"
          [id]="trackingName + '_follow_unfollow'"
          [ladda]="!!subscription"
          data-spinner-color="#000"
          [disabled]="!!subscription">
          <span *ngIf="!user.userNetwork?.observed">Follow</span>
          <span *ngIf="user.userNetwork?.observed">Unfollow</span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="user.biography">
    <h4 class="mt-15 mb-5">About Me</h4>
    <expand-for-more [content]="user.biography"></expand-for-more>
  </div>
  <user-achievements-scales [user]="user" [trackingName]="trackingName"></user-achievements-scales>
</div>
