import { Component, OnInit } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IExternalApplicationUser } from 'modules/external-applications/models/external-application-user.model';
import { UserExternalApplicationService } from 'modules/user/external-applications/services/user-external-application.service';
import { finalize, tap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'user-external-applications-list',
  templateUrl: 'user-external-applications-list.component.html',
})
export class UserExternalApplicationsListComponent implements OnInit {
  loading = false;
  applications: IExternalApplicationUser[] = [];

  constructor(
    private userExternalApplicationService: UserExternalApplicationService,
    private currentUser: CurrentUserService,
  ) {}

  ngOnInit(): void {
    this.loadApplications();
  }

  private loadApplications(): void {
    this.loading = true;
    this.userExternalApplicationService
      .query(this.currentUser.get().id)
      .pipe(
        tap((applications) => {
          this.applications = applications.sort((a, b) => a.application.name.localeCompare(b.application.name));
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }
}
