<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Generate Access Key</h4>
</div>

<form #generateAuthTokenForm="ngForm" (ngSubmit)="submit()" name="generateAuthTokenForm" class="form-horizontal">
  <div class="modal-body">
    <div class="row standard-margin-bottom">
      <div class="col-xs-12">Generate an access key?</div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <span class="font-bold text-danger">IMPORTANT:</span>
        This action is irreversible. The previous access key will be lost if it existed.
      </div>
    </div>

    <div class="row standard-margin-top">
      <div class="col-xs-12">
        <div class="form-group" [ngClass]="{ 'has-error': passwordField.invalid && passwordField.dirty }">
          <label for="password" class="col-sm-3 control-label text-left">Your Password</label>

          <div class="col-sm-5">
            <input
              class="form-control required"
              type="password"
              id="password"
              name="password"
              autocomplete="off"
              #passwordField="ngModel"
              placeholder="Password"
              maxLength="100"
              required
              [(ngModel)]="password"
              autoFocus />
            <p class="error-block" *ngIf="!!passwordField.errors?.required">Password is Required.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-sm alert-danger" *ngIf="error">{{ error }}</div>
  </div>

  <div class="modal-footer">
    <fieldset [disabled]="loading" class="no-margin-bottom">
      <button type="button" class="btn btn-warning" title="Cancel" (click)="closeModal()">Cancel</button>
      <button
        type="submit"
        class="btn btn-success"
        title="Generate"
        data-spinner-color="#000"
        [disabled]="loading || passwordField.invalid"
        [ladda]="loading">
        Generate Access Key
      </button>
    </fieldset>
  </div>
</form>
