import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { IUploadedTmpFile } from 'components/file-upload/single-file-upload.upgraded';
import { GlobalConfig } from 'core/environment';
import { SecurityService, UserSettingsService } from 'core/services';
import _ from 'lodash';
import { AchievementType } from 'modules/achievements/models/achievements.model';
import { IGroupAchievement } from 'modules/admin/group/achievements/models/group-achievements.model';
import { CollectionsService } from 'modules/course/services/collections.service.ajs-upgraded-provider';
import { CourseComponentsService } from 'modules/course/services/course-components.service.ajs-upgraded-provider';
import { Unsubscribable, from, tap } from 'rxjs';

@Component({
  standalone: false,
  selector: 'achievement-details',
  templateUrl: './achievement-details.component.html',
})
export class AchievementDetailsComponent implements OnInit, OnDestroy {
  @Input() achievement: IGroupAchievement;
  @Input() progress = {
    percentage: 0,
    totalPoints: 0,
    completedPoints: 0,
  };

  requestSubscriber?: Unsubscribable;
  badgeFile?: IUploadedTmpFile;
  selfView = false;
  points: number;
  AchievementType = AchievementType;
  leaderboardEnabled = _.get(this.globalConfig, 'settings.achievementsConfig.leaderboardEnabled');
  networkEnabled =
    this.userSettingsService.user_network_enabled &&
    this.securityService.isStateAvailable('main.user_network') &&
    _.get(this.currentUser.get(), 'userNetwork.profile');

  private _userId: number | null;
  constructor(
    private activeModal: NgbActiveModal,
    private globalConfig: GlobalConfig,
    private userSettingsService: UserSettingsService,
    private securityService: SecurityService,
    private currentUser: CurrentUserService,
    private courseComponentsService: CourseComponentsService,
    private collectionsService: CollectionsService,
  ) {}

  set userId(userId: number | null) {
    this._userId = userId;
    this.selfView = userId === this.currentUser.get().id;
  }

  get userId(): number | null {
    return this._userId;
  }

  ngOnInit() {
    if (this.selfView && !this.achievement.achieved) {
      // Complete Single Course

      if (this.achievement.achievementTypeId === AchievementType.COURSE) {
        // Collection type
        const achievementCourse = this.achievement.groupAchievementCourses[0];

        if (achievementCourse.formatTypeId === 4) {
          // Get collection structure
          this.requestSubscriber = from(
            this.courseComponentsService.getCompilationStructure(achievementCourse.courseId, this.userId),
          )
            .pipe(
              tap(
                (response) =>
                  (this.progress.percentage = this.collectionsService.aggregateRegistrationsInfo(
                    response.structure,
                  ).completed),
              ),
            )
            .subscribe();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  private clearRequestSubscriber(): void {
    this.requestSubscriber?.unsubscribe();
    delete this.requestSubscriber;
  }
}
