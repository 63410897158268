<span class="star-rating">
  <span
    *ngFor="let stars of range"
    aria-hidden="true"
    [ngClass]="[toInt(item.value) >= stars ? 'fa' : 'fa-regular', 'fa-star']">
  </span
  ><span class="inline-block xs-margin-left">
    <span class="sr-only">{{ item.value }} stars</span>&amp; Up
  </span>
</span>
